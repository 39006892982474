
import { useState, useEffect } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Ticket, MapPin, Calendar, Clock, CheckCircle, AlertCircle } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns";

interface Pass {
  id: string;
  venue_id: string;
  facility_name: string;
  pass_type: string;
  total_sessions: number;
  sessions_used: number;
  price: number;
  purchase_date: string;
  expiry_date: string;
  status: string;
  venue: {
    name: string;
    city: string;
  };
}

interface Session {
  id: string;
  pass_id: string;
  venue_id: string;
  facility_name: string;
  session_date: string;
  session_time: string;
  status: string;
  used_at: string | null;
  venue: {
    name: string;
    city: string;
  };
}

const ProfilePasses = () => {
  const { user } = useAuth();
  const [passes, setPasses] = useState<Pass[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      setLoading(true);
      
      try {
        // Fetch user passes directly from the venue_passes table
        const { data: passData, error: passError } = await supabase
          .from('venue_passes')
          .select(`
            id,
            venue_id,
            facility_name,
            pass_type,
            total_sessions,
            sessions_used,
            price,
            purchase_date,
            expiry_date,
            status,
            venues:venue_id (
              name,
              city
            )
          `)
          .eq('user_id', user.id)
          .order('purchase_date', { ascending: false });

        if (passError) {
          console.error("Error fetching passes:", passError);
          setLoading(false);
          return;
        }

        // Transform the data to match the expected format
        const userPasses = (passData || []).map(pass => ({
          ...pass,
          venue: pass.venues || { name: 'Unknown Venue', city: 'Unknown Location' }
        }));
        
        setPasses(userPasses);

        if (userPasses.length > 0) {
          const passIds = userPasses.map(pass => pass.id);
          
          // Fetch sessions for all passes
          const { data: sessionData, error: sessionError } = await supabase
            .from('venue_pass_sessions')
            .select(`
              id,
              pass_id,
              venue_id,
              facility_name,
              session_date,
              session_time,
              status,
              used_at,
              venues:venue_id (
                name,
                city
              )
            `)
            .in('pass_id', passIds)
            .order('session_date', { ascending: true });

          if (sessionError) {
            console.error("Error fetching sessions:", sessionError);
          } else {
            // Transform the data to match the expected format
            const transformedSessions = (sessionData || []).map(session => ({
              ...session,
              venue: session.venues || { name: 'Unknown Venue', city: 'Unknown Location' }
            }));
            
            setSessions(transformedSessions);
          }
        }
      } catch (error) {
        console.error("Error in data fetching:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'dd MMM yyyy');
    } catch (error) {
      return 'Invalid date';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'bg-green-500 hover:bg-green-600';
      case 'expired':
        return 'bg-gray-500 hover:bg-gray-600';
      case 'pending':
        return 'bg-yellow-500 hover:bg-yellow-600';
      default:
        return 'bg-blue-500 hover:bg-blue-600';
    }
  };

  const getSessionStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'used':
        return 'bg-gray-500 hover:bg-gray-600';
      case 'scheduled':
        return 'bg-green-500 hover:bg-green-600';
      case 'cancelled':
        return 'bg-red-500 hover:bg-red-600';
      default:
        return 'bg-blue-500 hover:bg-blue-600';
    }
  };

  // Filter sessions for the upcoming tab (scheduled sessions with future dates)
  const upcomingSessions = sessions.filter(session => {
    const sessionDate = new Date(session.session_date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return session.status.toLowerCase() === 'scheduled' && sessionDate >= today;
  }).sort((a, b) => new Date(a.session_date).getTime() - new Date(b.session_date).getTime());

  // Filter sessions for the past tab (used sessions or past scheduled sessions)
  const pastSessions = sessions.filter(session => {
    const sessionDate = new Date(session.session_date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return session.status.toLowerCase() === 'used' || 
           (session.status.toLowerCase() === 'scheduled' && sessionDate < today);
  }).sort((a, b) => new Date(b.session_date).getTime() - new Date(a.session_date).getTime());

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (passes.length === 0) {
    return (
      <div className="text-center p-8">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
          <Ticket className="h-8 w-8 text-gray-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">No Passes Found</h3>
        <p className="text-gray-500">You haven't purchased any venue passes yet.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">My Passes</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {passes.map((pass) => (
          <Card key={pass.id} className="overflow-hidden border border-gray-200 dark:border-gray-800">
            <CardHeader className="p-4 bg-gradient-to-r from-indigo-50 to-purple-50 dark:from-indigo-950/30 dark:to-purple-950/30">
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle className="text-lg font-bold">{pass.pass_type}</CardTitle>
                  <div className="text-sm text-gray-600 dark:text-gray-400 flex items-center mt-1">
                    <Ticket className="h-4 w-4 mr-1" />
                    {pass.facility_name}
                  </div>
                </div>
                <Badge className={`${getStatusColor(pass.status)}`}>
                  {pass.status}
                </Badge>
              </div>
            </CardHeader>
            <CardContent className="p-4">
              <div className="space-y-3">
                <div className="flex items-center text-sm">
                  <MapPin className="h-4 w-4 mr-2 text-gray-500" />
                  <span>{pass.venue.name}, {pass.venue.city}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <div className="flex items-center">
                    <Calendar className="h-4 w-4 mr-2 text-gray-500" />
                    <span>Valid until: {formatDate(pass.expiry_date)}</span>
                  </div>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Sessions Used:</span>
                  <span className="font-medium">{pass.sessions_used} / {pass.total_sessions}</span>
                </div>
                <div className="relative pt-2">
                  <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
                    <div 
                      style={{ width: `${(pass.sessions_used / pass.total_sessions) * 100}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
                    ></div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <Tabs defaultValue="upcoming" className="w-full">
          <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="upcoming" className="text-sm">
                Upcoming Sessions
              </TabsTrigger>
              <TabsTrigger value="past" className="text-sm">
                Past Sessions
              </TabsTrigger>
            </TabsList>
          </div>
          
          <TabsContent value="upcoming" className="p-0">
            {upcomingSessions.length === 0 ? (
              <div className="text-center p-8">
                <Calendar className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">No Upcoming Sessions</h3>
                <p className="text-gray-500 dark:text-gray-400">You don't have any upcoming sessions scheduled.</p>
              </div>
            ) : (
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {upcomingSessions.map((session) => (
                  <li key={session.id} className="p-4 hover:bg-gray-50 dark:hover:bg-gray-900/20 transition-colors">
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="font-medium">{session.facility_name}</div>
                        <div className="text-sm text-gray-600 dark:text-gray-400">
                          {session.venue.name}, {session.venue.city}
                        </div>
                        <div className="mt-2 flex items-center text-sm">
                          <Calendar className="h-4 w-4 mr-2 text-gray-500" />
                          <span>{formatDate(session.session_date)}</span>
                          <Clock className="h-4 w-4 ml-4 mr-2 text-gray-500" />
                          <span>{session.session_time}</span>
                        </div>
                      </div>
                      <Badge className={getSessionStatusColor(session.status)}>
                        {session.status}
                      </Badge>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </TabsContent>
          
          <TabsContent value="past" className="p-0">
            {pastSessions.length === 0 ? (
              <div className="text-center p-8">
                <Clock className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">No Past Sessions</h3>
                <p className="text-gray-500 dark:text-gray-400">You don't have any past sessions.</p>
              </div>
            ) : (
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {pastSessions.map((session) => (
                  <li key={session.id} className="p-4 hover:bg-gray-50 dark:hover:bg-gray-900/20 transition-colors">
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="font-medium">{session.facility_name}</div>
                        <div className="text-sm text-gray-600 dark:text-gray-400">
                          {session.venue.name}, {session.venue.city}
                        </div>
                        <div className="mt-2 flex items-center text-sm">
                          <Calendar className="h-4 w-4 mr-2 text-gray-500" />
                          <span>{formatDate(session.session_date)}</span>
                          <Clock className="h-4 w-4 ml-4 mr-2 text-gray-500" />
                          <span>{session.session_time}</span>
                        </div>
                      </div>
                      <Badge className={getSessionStatusColor(session.status)}>
                        {session.status === 'scheduled' ? 'Missed' : session.status}
                      </Badge>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default ProfilePasses;
