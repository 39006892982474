import { supabase } from "@/integrations/supabase/client";
import { SelectedTimeSlot } from "@/components/passes/VenuePassesScreen";
import { toast } from "sonner";

// Create a type for the pass payment parameters
export interface PassPaymentParams {
  userId?: string;
  venueId: string;
  facilityId?: string;
  facilityName: string;
  passType: {
    id: string;
    name: string;
    totalSessions: number;
    sessionsPerWeek: number;
    discount: number;
  };
  price: number;
  selectedTimeSlots: SelectedTimeSlot[];
  paymentId?: string;
  email?: string;
}

// Function to create a venue pass order with Razorpay
export const createRazorpayPassOrder = async (amount: number, passDetails: any) => {
  try {
    console.log("Creating Razorpay order with amount:", amount, "and details:", passDetails);
    
    // Ensure venueId is present in passDetails
    if (!passDetails.venueId) {
      console.error("Missing required venueId in passDetails:", passDetails);
      throw new Error("Missing required venueId parameter");
    }
    
    const response = await supabase.functions.invoke('create-razorpay-pass-payment', {
      body: { passDetails: { ...passDetails, amount } }
    });

    if (response.error) {
      console.error("Error calling Razorpay function:", response.error);
      throw new Error(response.error.message);
    }

    console.log("Razorpay order created successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating Razorpay pass order:", error);
    throw error;
  }
};

// Modified to handle both direct database save and server-side save for mobile compatibility
export const savePassToDatabase = async (params: PassPaymentParams) => {
  try {
    console.log("Starting savePassToDatabase with params:", JSON.stringify(params, null, 2));
    
    // Detect if we're on mobile
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    console.log(`Device detected as: ${isMobile ? 'mobile' : 'desktop'}`);
    
    if (isMobile && params.paymentId) {
      console.log("Mobile device detected with payment ID, will rely on server-side pass creation");
      // For mobile, we'll rely on the server-side pass creation in verify-razorpay-pass-payment
      // This works around potential RLS/timeout issues on mobile
      return { id: 'server-side-creation', mobileFlow: true };
    }
    
    // For desktop or when no payment ID is present, proceed with client-side creation
    // Validate required parameters
    if (!params.userId) {
      console.error("=== DETAILED DEBUG: Missing required userId parameter ===");
      throw new Error("Missing required userId parameter");
    }
    
    if (!params.venueId) {
      console.error("=== DETAILED DEBUG: Missing required venueId parameter ===");
      throw new Error("Missing required venueId parameter");
    }
    
    if (!params.facilityName || !params.passType) {
      console.error("=== DETAILED DEBUG: Missing required facility or pass type parameters ===");
      throw new Error("Missing required facility or pass type parameters");
    }
    
    console.log("=== DETAILED DEBUG: All required parameters validated successfully ===");
    
    // Calculate expiry date (1 month from now)
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);

    console.log("Calculated expiry date:", expiryDate.toISOString());
    
    // Check if this pass already exists to prevent duplicates
    if (params.paymentId) {
      console.log("Payment ID exists, checking for existing pass:", params.paymentId);
      
      try {
        const existingPassCheckPromise = supabase
          .from('venue_passes')
          .select('id')
          .eq('payment_id', params.paymentId)
          .maybeSingle();
          
        // Add timeout to detect if this operation hangs
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error("Existing pass check timed out after 10 seconds")), 10000);
        });
        
        const { data: existingPass, error: checkError } = await Promise.race([
          existingPassCheckPromise,
          timeoutPromise
        ]) as { data: any, error: any };
        
        console.log("Existing pass check result:", existingPass, checkError);
        
        if (checkError) {
          console.error("Error checking for existing pass:", checkError);
        }
        
        if (existingPass) {
          console.log("Pass with this payment ID already exists:", existingPass.id);
          return existingPass;
        }
      } catch (existingPassError) {
        console.error("Exception while checking existing pass:", existingPassError);
        if (existingPassError instanceof Error && existingPassError.message.includes("timed out")) {
          console.error("Existing pass check timed out, continuing with creation");
        } else {
          throw existingPassError;
        }
      }
    }
    
    console.log("About to insert main pass record to venue_passes table");
    
    // Try using the database function first (more secure)
    try {
      console.log("=== DETAILED DEBUG: Attempting to create pass using database function ===");
      console.log("Database function parameters:", {
        user_id_param: params.userId,
        venue_id_param: params.venueId,
        facility_id_param: params.facilityId || null,
        facility_name_param: params.facilityName,
        pass_type_param: params.passType.name,
        sessions_per_week_param: params.passType.sessionsPerWeek,
        total_sessions_param: params.passType.totalSessions,
        price_param: params.price,
        expiry_date_param: expiryDate.toISOString(),
        selected_time_slots_param: JSON.stringify(params.selectedTimeSlots),
        payment_id_param: params.paymentId || '',
        payment_status_param: params.paymentId ? 'paid' : 'pending',
        status_param: params.paymentId ? 'active' : 'pending'
      });
      
      // Ensure all parameters are the correct type for the database function
      const dbFunctionPromise = supabase.rpc('create_venue_pass', {
        user_id_param: params.userId,
        venue_id_param: params.venueId,
        facility_id_param: params.facilityId || null,
        facility_name_param: params.facilityName,
        pass_type_param: params.passType.name,
        sessions_per_week_param: params.passType.sessionsPerWeek,
        total_sessions_param: params.passType.totalSessions,
        price_param: params.price,
        expiry_date_param: expiryDate.toISOString(),
        selected_time_slots_param: JSON.stringify(params.selectedTimeSlots),
        payment_id_param: params.paymentId || '',  // Convert undefined to empty string
        payment_status_param: params.paymentId ? 'paid' : 'pending',
        status_param: params.paymentId ? 'active' : 'pending'
      });
      
      // Add timeout to detect if this operation hangs
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error("Database function call timed out after 15 seconds")), 15000);
      });
      
      const response = await Promise.race([
        dbFunctionPromise,
        timeoutPromise
      ]);
      
      const { data: funcData, error: funcError } = response as { data: any, error: any };

      console.log("=== DETAILED DEBUG: Database function response ===", funcData, funcError);
      
      if (funcError) {
        console.error("Error using database function to create pass:", funcError);
        console.error("=== DETAILED DEBUG: Function error code:", funcError.code);
        console.error("=== DETAILED DEBUG: Function error message:", funcError.message);
        console.error("=== DETAILED DEBUG: Function error details:", funcError.details);
        console.log("=== DETAILED DEBUG: Will fall back to direct insert ===");
      } else if (funcData) {
        console.log("Pass created successfully using database function:", funcData);
        // Fixed: Use type assertion to access the id property
        const passId = (funcData as { id: string }).id;
        console.log("=== DETAILED DEBUG: Pass created with ID:", passId);
        
        // Create sessions using the pass ID returned from the function
        if (passId && params.selectedTimeSlots.length > 0) {
          console.log("=== DETAILED DEBUG: Moving to session creation for pass ID:", passId);
          await createPassSessions(passId, params);
        }
        
        return { id: passId };
      }
    } catch (funcCatchError) {
      console.error("=== DETAILED DEBUG: Exception using database function ===", funcCatchError);
      if (funcCatchError instanceof Error) {
        console.error("=== DETAILED DEBUG: Error name:", funcCatchError.name);
        console.error("=== DETAILED DEBUG: Error message:", funcCatchError.message);
        console.error("=== DETAILED DEBUG: Error stack:", funcCatchError.stack);
        
        if (funcCatchError.message.includes("timed out")) {
          console.error("=== DETAILED DEBUG: Database function timed out, falling back to direct insert ===");
          // Continue with direct insert despite timeout
        } else {
          throw funcCatchError;
        }
      }
      console.log("=== DETAILED DEBUG: Will fall back to direct insert ===");
    }
    
    // Fall back to direct insert if the function approach failed
    console.log("=== DETAILED DEBUG: Attempting direct insert to venue_passes table ===");
    console.log("=== DETAILED DEBUG: Direct insert parameters ===", {
      user_id: params.userId,
      venue_id: params.venueId,
      facility_id: params.facilityId || null,
      facility_name: params.facilityName,
      pass_type: params.passType.name,
      sessions_per_week: params.passType.sessionsPerWeek,
      total_sessions: params.passType.totalSessions,
      price: params.price,
      expiry_date: expiryDate.toISOString(),
      selected_time_slots: params.selectedTimeSlots,
      payment_id: params.paymentId || '',
      payment_status: params.paymentId ? 'paid' : 'pending',
      status: params.paymentId ? 'active' : 'pending'
    });
    
    try {
      const directInsertPromise = supabase
        .from('venue_passes')
        .insert([{
          user_id: params.userId,
          venue_id: params.venueId,
          facility_id: params.facilityId || null,
          facility_name: params.facilityName,
          pass_type: params.passType.name,
          sessions_per_week: params.passType.sessionsPerWeek,
          total_sessions: params.passType.totalSessions,
          price: params.price,
          expiry_date: expiryDate.toISOString(),
          selected_time_slots: params.selectedTimeSlots,
          payment_id: params.paymentId || '',  // Convert undefined to empty string
          payment_status: params.paymentId ? 'paid' : 'pending',
          status: params.paymentId ? 'active' : 'pending'
        }])
        .select()
        .single();
        
      // Add timeout to detect if this operation hangs
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error("Direct insert timed out after 15 seconds")), 15000);
      });
      
      const response = await Promise.race([
        directInsertPromise,
        timeoutPromise
      ]);
      
      const { data: passData, error: passError } = response as { data: any, error: any };

      console.log("=== DETAILED DEBUG: Direct insert result ===", passData, passError);

      if (passError) {
        console.error("Error saving pass:", passError);
        console.error("=== DETAILED DEBUG: Error code:", passError.code);
        console.error("=== DETAILED DEBUG: Error message:", passError.message);
        console.error("=== DETAILED DEBUG: Error details:", passError.details);
        
        if (passError.code === '42501') {
          console.error("=== DETAILED DEBUG: This appears to be a permissions issue (42501) ===");
        }
        if (passError.code === '23505') {
          console.error("=== DETAILED DEBUG: This appears to be a unique constraint violation (23505) ===");
        }
        if (passError.code === 'PGRST116') {
          console.error("=== DETAILED DEBUG: This appears to be a Row-Level Security violation (PGRST116) ===");
        }
        
        throw passError;
      }

      if (!passData) {
        console.error("=== DETAILED DEBUG: No pass data returned after insert ===");
        throw new Error("Failed to insert pass record");
      }

      console.log("=== DETAILED DEBUG: Pass saved successfully ===", passData);
      
      // Create individual sessions for each selected time slot
      if (passData && params.selectedTimeSlots.length > 0) {
        console.log("=== DETAILED DEBUG: Moving to session creation ===");
        await createPassSessions(passData.id, params);
      } else {
        console.warn("=== DETAILED DEBUG: No time slots to create sessions for ===");
      }

      return passData;
    } catch (directInsertError) {
      console.error("=== DETAILED DEBUG: Exception during direct insert ===", directInsertError);
      if (directInsertError instanceof Error) {
        console.error("=== DETAILED DEBUG: Error name:", directInsertError.name);
        console.error("=== DETAILED DEBUG: Error message:", directInsertError.message);
        console.error("=== DETAILED DEBUG: Error stack:", directInsertError.stack);
        
        if (directInsertError.message.includes("timed out")) {
          console.error("=== DETAILED DEBUG: Direct insert timed out ===");
          // Return a basic object to allow the flow to continue
          return { id: "unknown-fallback-id-after-timeout" };
        }
      }
      throw directInsertError;
    }
  } catch (error) {
    console.error("Top level error in savePassToDatabase:", error);
    throw error;
  }
};

// Add a new function to ensure verification works even on mobile
export const verifyRazorpayPassPayment = async (paymentId: string, orderId: string, signature: string, passDetails: PassPaymentParams) => {
  try {
    console.log("Verifying Razorpay payment:", { paymentId, orderId });
    
    // Detect if we're on mobile
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    // For mobile devices, include the complete pass details in the verification request
    // This allows the server to create the pass if needed
    const requestBody = isMobile 
      ? { paymentId, orderId, signature, passDetails }
      : { paymentId, orderId, signature };
    
    const response = await supabase.functions.invoke('verify-razorpay-pass-payment', {
      body: requestBody
    });

    if (response.error) {
      console.error("Error verifying payment:", response.error);
      throw new Error(response.error.message);
    }

    console.log("Payment verification successful:", response.data);
    return true;
  } catch (error) {
    console.error("Error in verifyRazorpayPassPayment:", error);
    throw error;
  }
};

// Helper function to create pass sessions
async function createPassSessions(passId: string, params: PassPaymentParams) {
  console.log(`=== DETAILED DEBUG: Creating ${params.selectedTimeSlots.length} time slots for the next 4 weeks for pass ${passId} ===`);
  
  try {
    // Create the first four weeks of sessions
    const sessions = [];
    const today = new Date();

    // For each selected time slot, create sessions for the next 4 weeks
    for (let week = 0; week < 4; week++) {
      for (const slot of params.selectedTimeSlots) {
        const sessionDate = new Date(today);
        
        // Map day string to day number (0 = Sunday, 1 = Monday, etc.)
        const dayMap: {[key: string]: number} = {
          'sunday': 0, 'monday': 1, 'tuesday': 2, 'wednesday': 3,
          'thursday': 4, 'friday': 5, 'saturday': 6
        };
        
        const targetDay = dayMap[slot.day.toLowerCase()];
        if (targetDay === undefined) {
          console.warn(`=== DETAILED DEBUG: Invalid day string: ${slot.day} ===`);
          continue;
        }
        
        const currentDay = sessionDate.getDay();
        
        // Calculate days to add to reach the target day
        let daysToAdd = targetDay - currentDay;
        if (daysToAdd <= 0) daysToAdd += 7; // If target day is today or earlier, go to next week
        
        // Add the calculated days plus 7 days for each week after the first
        daysToAdd += (week * 7);
        sessionDate.setDate(sessionDate.getDate() + daysToAdd);
        
        sessions.push({
          pass_id: passId,
          venue_id: params.venueId,
          facility_id: params.facilityId || null,
          facility_name: params.facilityName,
          session_date: sessionDate.toISOString().split('T')[0],
          session_time: slot.time,
          status: 'scheduled'
        });
      }
    }

    if (sessions.length > 0) {
      console.log(`=== DETAILED DEBUG: Created ${sessions.length} pass sessions objects, now inserting to database ===`);
      
      // Try using the database function first
      try {
        console.log("=== DETAILED DEBUG: Attempting to create sessions using database function ===");
        
        const dbFunctionPromise = supabase.rpc('create_venue_pass_sessions', {
          sessions_param: sessions
        });
        
        // Add timeout to detect if this operation hangs
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error("Session creation function timed out after 15 seconds")), 15000);
        });
        
        const response = await Promise.race([
          dbFunctionPromise,
          timeoutPromise
        ]);
        
        const { error: funcError } = response as { data: any, error: any };

        console.log("=== DETAILED DEBUG: Session creation function response ===", funcError);

        if (funcError) {
          console.error("=== DETAILED DEBUG: Error using database function to create sessions ===", funcError);
          console.error("=== DETAILED DEBUG: Function error code:", funcError.code);
          console.error("=== DETAILED DEBUG: Function error message:", funcError.message);
          console.error("=== DETAILED DEBUG: Function error details:", funcError.details);
          console.log("=== DETAILED DEBUG: Will fall back to direct insert in batches ===");
        } else {
          console.log("=== DETAILED DEBUG: Successfully created all sessions using database function ===");
          return;
        }
      } catch (funcCatchError) {
        console.error("=== DETAILED DEBUG: Exception using database function for sessions ===", funcCatchError);
        if (funcCatchError instanceof Error) {
          console.error("=== DETAILED DEBUG: Error name:", funcCatchError.name);
          console.error("=== DETAILED DEBUG: Error message:", funcCatchError.message);
          console.error("=== DETAILED DEBUG: Error stack:", funcCatchError.stack);
          
          if (funcCatchError.message.includes("timed out")) {
            console.error("=== DETAILED DEBUG: Database function for sessions timed out, falling back to direct insert ===");
            // Continue with direct insert despite timeout
          }
        }
        console.log("=== DETAILED DEBUG: Will fall back to direct insert in batches ===");
      }
      
      // Create sessions in smaller batches to avoid potential issues
      const batchSize = 5; // Smaller batch size to avoid potential issues
      for (let i = 0; i < sessions.length; i += batchSize) {
        const batch = sessions.slice(i, i + batchSize);
        console.log(`=== DETAILED DEBUG: Creating sessions batch ${Math.floor(i / batchSize) + 1}, size: ${batch.length} ===`);
        
        try {
          const batchInsertPromise = supabase
            .from('venue_pass_sessions')
            .insert(batch);
            
          // Add timeout to detect if this operation hangs
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error(`Batch ${Math.floor(i / batchSize) + 1} insert timed out after 10 seconds`)), 10000);
          });
          
          const response = await Promise.race([
            batchInsertPromise,
            timeoutPromise
          ]);
          
          const { error: sessionsError } = response as { data: any, error: any };

          console.log(`=== DETAILED DEBUG: Sessions batch ${Math.floor(i / batchSize) + 1} result ===`, sessionsError);
  
          if (sessionsError) {
            console.error(`=== DETAILED DEBUG: Error creating pass sessions batch ${Math.floor(i / batchSize) + 1} ===`, sessionsError);
            console.error("=== DETAILED DEBUG: Error code:", sessionsError.code);
            console.error("=== DETAILED DEBUG: Error message:", sessionsError.message);
            console.error("=== DETAILED DEBUG: Error details:", sessionsError.details);
            
            if (sessionsError.code === '42501') {
              console.error("=== DETAILED DEBUG: This appears to be a permissions issue (42501) ===");
            }
            if (sessionsError.code === 'PGRST116') {
              console.error("=== DETAILED DEBUG: This appears to be a Row-Level Security violation (PGRST116) ===");
            }
            
            console.log("=== DETAILED DEBUG: Will continue with the next batch ===");
          } else {
            console.log(`=== DETAILED DEBUG: Successfully created pass sessions batch ${Math.floor(i / batchSize) + 1} ===`);
          }
        } catch (batchError) {
          console.error(`=== DETAILED DEBUG: Exception in batch ${Math.floor(i / batchSize) + 1} ===`, batchError);
          if (batchError instanceof Error) {
            console.error("=== DETAILED DEBUG: Error name:", batchError.name);
            console.error("=== DETAILED DEBUG: Error message:", batchError.message);
            console.error("=== DETAILED DEBUG: Error stack:", batchError.stack);
            
            if (batchError.message.includes("timed out")) {
              console.error(`=== DETAILED DEBUG: Batch ${Math.floor(i / batchSize) + 1} timed out, continuing with next batch ===`);
              // Continue with next batch despite timeout
            }
          }
        }
      }
      
      console.log("=== DETAILED DEBUG: All pass sessions creation attempts completed ===");
    } else {
      console.warn("=== DETAILED DEBUG: No valid sessions to create ===");
    }
  } catch (sessionError) {
    // Log error but don't throw, since the main pass is already created
    console.error("=== DETAILED DEBUG: Error creating pass sessions ===", sessionError);
    if (sessionError instanceof Error) {
      console.error("=== DETAILED DEBUG: Error name:", sessionError.name);
      console.error("=== DETAILED DEBUG: Error message:", sessionError.message);
      console.error("=== DETAILED DEBUG: Error stack:", sessionError.stack);
    }
  }
}
