
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Venue } from "@/types/venue";
import VenuePassesScreen from "@/components/passes/VenuePassesScreen";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

const VenuePasses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const isMobile = useIsMobile();
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVenueData = async () => {
      setLoading(true);
      
      // First, check if the venue data was passed in location state
      if (location.state?.venue) {
        const venueData = location.state.venue;
        console.info("Venue data received:", venueData);
        
        if (!venueData.id) {
          console.error("Venue data missing ID property:", venueData);
          toast.error("Invalid venue data. Missing venue ID.");
          navigate("/venues");
          return;
        }
        
        // Cast the venue data to ensure it matches the Venue type
        setVenue(venueData as Venue);
        setLoading(false);
        return;
      }
      
      // If not in state, check if we have an ID in the URL params
      const venueId = params.id;
      if (venueId) {
        console.info("Fetching venue data for ID from URL params:", venueId);
        try {
          const { data, error } = await supabase
            .from('venues')
            .select('*')
            .eq('id', venueId)
            .single();
            
          if (error) {
            console.error("Error fetching venue data:", error);
            toast.error("Failed to load venue information");
            navigate("/venues");
            return;
          }
          
          if (!data) {
            console.error("No venue found with ID:", venueId);
            toast.error("Venue not found");
            navigate("/venues");
            return;
          }
          
          console.info("Fetched venue data from database:", data);
          setVenue(data as Venue);
          setLoading(false);
        } catch (error) {
          console.error("Error in venue fetch operation:", error);
          toast.error("Something went wrong when loading venue data");
          navigate("/venues");
        }
        return;
      }
      
      // If we don't have venue data in state or URL params, redirect back
      console.warn("No venue data in location state or URL params, redirecting to venues");
      navigate("/venues");
    };
    
    fetchVenueData();
  }, [location.state, navigate, params.id]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading || !venue) {
    return (
      <div className="flex min-h-[60vh] items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-br from-amber-300 to-yellow-400 flex items-center justify-center animate-pulse">
            <div className="w-10 h-10 rounded-full bg-white/80"></div>
          </div>
          <h2 className="text-xl font-semibold mb-2 bg-gradient-to-r from-amber-700 to-yellow-600 bg-clip-text text-transparent">Loading venue details...</h2>
          <p className="text-amber-600 dark:text-amber-400 text-sm">Please wait while we retrieve your pass information</p>
        </div>
      </div>
    );
  }

  // Additional validation to ensure venue ID is present
  if (!venue.id) {
    console.error("Venue is missing ID property after state update:", venue);
    toast.error("Invalid venue data. Missing venue ID.");
    navigate("/venues");
    return null;
  }

  return (
    <div className={`container max-w-4xl mx-auto px-4 ${isMobile ? 'pt-16 pb-4' : 'py-8'}`}>
      <div className="flex items-center mb-6">
        <Button
          variant="ghost"
          size="icon"
          onClick={handleBack}
          className="mr-2 text-amber-700 hover:text-amber-800 hover:bg-amber-100/50"
        >
          <ChevronLeft className="h-5 w-5" />
        </Button>
        <h1 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold bg-gradient-to-r from-amber-700 via-yellow-600 to-amber-800 bg-clip-text text-transparent`}>
          Venue Passes - {venue.name}
        </h1>
      </div>
      
      <VenuePassesScreen venue={venue} />
    </div>
  );
};

export default VenuePasses;
