
import { useLocation, Link } from "react-router-dom";
import { CheckCircle, Calendar, Ticket, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";

const PassSuccess = () => {
  const location = useLocation();
  const passDetails = location.state || {};

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen pt-32 bg-gradient-to-b from-indigo-50 to-white dark:from-indigo-950/20 dark:to-gray-900">
      <div className="max-w-md mx-auto px-4">
        <div className="text-center space-y-6">
          <div className="animate-bounce mb-4">
            <CheckCircle className="w-24 h-24 text-green-500 mx-auto" />
          </div>
          
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            Pass Purchased Successfully!
          </h1>
          
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg border border-indigo-100 dark:border-indigo-800/30">
            <div className="flex items-center justify-center mb-5">
              <Ticket className="h-6 w-6 mr-2 text-indigo-600 dark:text-indigo-400" />
              <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                {passDetails.passType || "Your Pass"} 
              </h2>
            </div>
            
            <div className="space-y-4 mb-6">
              <div className="flex justify-between py-2 border-b border-gray-100 dark:border-gray-700">
                <span className="text-gray-600 dark:text-gray-400">Facility:</span>
                <span className="font-medium text-gray-900 dark:text-white">{passDetails.facilityName || "Facility"}</span>
              </div>
              
              <div className="flex justify-between py-2 border-b border-gray-100 dark:border-gray-700">
                <span className="text-gray-600 dark:text-gray-400">Sessions:</span>
                <span className="font-medium text-gray-900 dark:text-white">{passDetails.sessions || 0} sessions</span>
              </div>
              
              <div className="flex justify-between py-2 border-b border-gray-100 dark:border-gray-700">
                <span className="text-gray-600 dark:text-gray-400">Amount Paid:</span>
                <span className="font-medium text-green-600 dark:text-green-400">₹{(passDetails.amount || 0).toLocaleString()}</span>
              </div>
              
              <div className="flex items-center justify-center mt-2 text-gray-600 dark:text-gray-400">
                <Calendar className="h-4 w-4 mr-2" />
                <span className="text-sm">Your sessions have been scheduled</span>
              </div>
            </div>
            
            <div className="space-y-4">
              <Link to="/my-bookings?tab=passes">
                <Button className="w-full bg-indigo-600 hover:bg-indigo-700 text-white">
                  View My Passes
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </Link>
              
              <Link to="/venues">
                <Button variant="outline" className="w-full">
                  Browse More Venues
                </Button>
              </Link>
            </div>
          </div>
          
          <p className="text-sm text-gray-600 dark:text-gray-400">
            A confirmation has been sent to your email. You can also view your passes in your profile.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PassSuccess;
