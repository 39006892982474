
import { useState, useEffect } from "react";
import { Facility, PassType, SelectedTimeSlot } from "./VenuePassesScreen";
import { ChevronLeft, Calendar, Clock, Check, Trash } from "lucide-react";
import { CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useIsMobile } from "@/hooks/use-mobile";

const timeOptions = [
  "06:00 AM", "07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM",
  "12:00 PM", "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM",
  "06:00 PM", "07:00 PM", "08:00 PM", "09:00 PM", "10:00 PM"
];

const weekdays = [
  { id: "monday", name: "Monday" },
  { id: "tuesday", name: "Tuesday" },
  { id: "wednesday", name: "Wednesday" },
  { id: "thursday", name: "Thursday" },
  { id: "friday", name: "Friday" },
  { id: "saturday", name: "Saturday" },
  { id: "sunday", name: "Sunday" },
];

const TimeSlotSelector = ({
  facility,
  passType,
  selectedTimeSlots,
  onTimeSlotSelect,
  onGoBack
}: {
  facility: Facility;
  passType: PassType;
  selectedTimeSlots: SelectedTimeSlot[];
  onTimeSlotSelect: (timeSlots: SelectedTimeSlot[]) => void;
  onGoBack: () => void;
}) => {
  const isMobile = useIsMobile();
  const [currentDay, setCurrentDay] = useState(weekdays[0].id);
  const [dayTimeSlots, setDayTimeSlots] = useState<Record<string, string>>({});

  // Load saved time slots when component mounts
  useEffect(() => {
    const savedData: Record<string, string> = {};
    selectedTimeSlots.forEach(slot => {
      savedData[slot.day] = slot.time;
    });
    setDayTimeSlots(savedData);
  }, [selectedTimeSlots]);

  const handleDayChange = (day: string) => {
    setCurrentDay(day);
  };

  const handleTimeSelect = (day: string, time: string) => {
    // If the time is already selected for this day, unselect it
    if (dayTimeSlots[day] === time) {
      const updatedTimeSlots = { ...dayTimeSlots };
      delete updatedTimeSlots[day];
      setDayTimeSlots(updatedTimeSlots);
      return;
    }
    
    // If we already have enough selected slots, and this is a new day, don't add it
    if (Object.keys(dayTimeSlots).length >= passType.sessionsPerWeek && !dayTimeSlots[day]) {
      return;
    }
    
    // Set the time for the current day
    const updatedTimeSlots = { ...dayTimeSlots, [day]: time };
    setDayTimeSlots(updatedTimeSlots);
  };

  const handleClearDayTimeSlot = () => {
    if (dayTimeSlots[currentDay]) {
      const updatedTimeSlots = { ...dayTimeSlots };
      delete updatedTimeSlots[currentDay];
      setDayTimeSlots(updatedTimeSlots);
    }
  };

  const handleSubmit = () => {
    const timeSlots = Object.entries(dayTimeSlots).map(([day, time]) => ({
      day,
      time
    }));
    onTimeSlotSelect(timeSlots);
  };

  const canProceed = Object.keys(dayTimeSlots).length >= passType.sessionsPerWeek;
  const remainingSlots = passType.sessionsPerWeek - Object.keys(dayTimeSlots).length;
  const maxSlotsReached = Object.keys(dayTimeSlots).length >= passType.sessionsPerWeek;

  return (
    <CardContent className={`p-6 ${isMobile ? 'pb-16' : ''}`}>
      <div className="flex items-center mb-6">
        <button
          onClick={onGoBack}
          className="p-1 mr-2 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        <div>
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
            Select Time Slots
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mt-1">
            Choose {passType.sessionsPerWeek} weekly time slot{passType.sessionsPerWeek > 1 ? 's' : ''}
          </p>
        </div>
      </div>

      <div className="mb-6">
        <div className="overflow-x-auto pb-2">
          <div className="flex space-x-2 min-w-max">
            {weekdays.map((day) => {
              const isSelected = day.id === currentDay;
              const hasTimeSlot = dayTimeSlots[day.id];
              
              return (
                <div
                  key={day.id}
                  onClick={() => handleDayChange(day.id)}
                  className={`flex flex-col items-center p-3 rounded-lg cursor-pointer transition-all ${
                    isSelected 
                      ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                      : hasTimeSlot
                        ? 'bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800/30 text-green-800 dark:text-green-400'
                        : 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-300'
                  }`}
                >
                  <span className={`text-xs ${isSelected ? 'text-indigo-100' : ''}`}>
                    {day.name.substring(0, 3)}
                  </span>
                  <div className="mt-1 flex items-center">
                    {hasTimeSlot ? (
                      <Check className="h-4 w-4 text-green-600 dark:text-green-400" />
                    ) : (
                      <Clock className={`h-4 w-4 ${isSelected ? 'text-white' : 'text-gray-500 dark:text-gray-400'}`} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="font-semibold text-gray-800 dark:text-white mb-3 flex items-center">
          <Clock className="h-4 w-4 mr-2 text-indigo-600 dark:text-indigo-400" />
          Available Time Slots for {weekdays.find(d => d.id === currentDay)?.name}
        </h3>

        <RadioGroup
          value={dayTimeSlots[currentDay] || ""}
          onValueChange={(value) => handleTimeSelect(currentDay, value)}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2"
        >
          {timeOptions.map((time) => {
            const isSelected = dayTimeSlots[currentDay] === time;
            
            // Disable if max slots reached and this day doesn't already have a slot
            const isDisabled = maxSlotsReached && !dayTimeSlots[currentDay];
            
            return (
              <div key={time} className="relative">
                <RadioGroupItem
                  value={time}
                  id={`time-${time}`}
                  className="sr-only"
                  disabled={isDisabled}
                />
                <label
                  htmlFor={`time-${time}`}
                  className={`flex items-center justify-center p-3 rounded-lg border text-sm cursor-pointer transition-all ${
                    isSelected
                      ? 'bg-indigo-100 dark:bg-indigo-900/50 border-indigo-300 dark:border-indigo-700 text-indigo-800 dark:text-indigo-300'
                      : isDisabled
                        ? 'bg-gray-100 dark:bg-gray-800 border-gray-200 dark:border-gray-700 text-gray-400 dark:text-gray-600 opacity-60 cursor-not-allowed'
                        : 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:border-indigo-300 dark:hover:border-indigo-700'
                  }`}
                >
                  {time}
                  {isSelected && (
                    <Check className="h-4 w-4 ml-2 text-indigo-600 dark:text-indigo-400" />
                  )}
                </label>
              </div>
            );
          })}
        </RadioGroup>
        
        {/* Clear Time Slots Button */}
        {dayTimeSlots[currentDay] && (
          <div className="mt-4 flex justify-center">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleClearDayTimeSlot}
              className="border-red-200 text-red-600 hover:bg-red-50 hover:text-red-700 dark:border-red-800 dark:text-red-400 dark:hover:bg-red-900/20 transition-all"
            >
              <Trash className="h-4 w-4 mr-2" />
              Clear time slot
            </Button>
          </div>
        )}
      </div>

      {maxSlotsReached && !dayTimeSlots[currentDay] && (
        <div className="mb-6 p-3 bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-800/30 rounded-lg">
          <p className="text-amber-700 dark:text-amber-400 text-sm flex items-center">
            <Calendar className="h-4 w-4 mr-2" />
            You've selected the maximum number of slots for this pass. To select this day, remove a previously selected day.
          </p>
        </div>
      )}

      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mt-6">
        <div className="text-sm">
          {canProceed ? (
            <span className="text-green-600 dark:text-green-400 font-medium flex items-center">
              <Check className="h-4 w-4 mr-1" />
              All slots selected! You're good to go.
            </span>
          ) : (
            <span className="text-amber-600 dark:text-amber-400 font-medium">
              Please select {remainingSlots} more time slot{remainingSlots > 1 ? 's' : ''}.
            </span>
          )}
        </div>
        
        <Button
          onClick={handleSubmit}
          disabled={!canProceed}
          className={`w-full sm:w-auto ${
            canProceed 
              ? 'bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700'
              : ''
          }`}
        >
          Continue to Summary
        </Button>
      </div>

      <div className={`mt-4 ${isMobile ? 'text-sm' : ''}`}>
        <div className="p-4 rounded-lg bg-indigo-50 dark:bg-indigo-900/20 border border-indigo-100 dark:border-indigo-800/30">
          <p className="text-xs text-indigo-600 dark:text-indigo-400">
            These weekly slots will be reserved for you for the next 4 weeks. Each session is 60 minutes long.
          </p>
        </div>
      </div>
    </CardContent>
  );
};

export default TimeSlotSelector;
