import React, { useState, useEffect } from "react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ExternalLink, MapPin, Clock, Users, Phone, Globe, Star, X, Copy, Check, Map } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { toast } from "@/components/ui/use-toast";
import { getExternalImage } from "@/utils/venueImageUtils";
import InAppBrowser from "@/components/InAppBrowser";

interface Academy {
  id: string;
  name: string;
  description?: string | null;
  address?: string; // Changed from required to optional to match other Academy types
  city: string;
  state?: string; // Made optional to match other Academy types
  pincode?: string; // Made optional to match other Academy types
  images?: string[];
  sports?: string[];
  opening_time?: string; // Made optional to match other Academy types
  closing_time?: string; // Made optional to match other Academy types
  max_capacity?: number;
  monthly_fee: number;
  rating?: number;
  total_ratings?: number;
  is_external: boolean;
  is_bookable?: boolean;
  phone?: string;
  website?: string;
  place_id?: string;
  maps_url?: string;
  data_source?: string;
  opening_hours?: string[];
  reviews?: any[];
  image_url?: string;
}

interface AcademyDetailsSheetProps {
  academy: Academy | null;
  isOpen: boolean;
  onClose: () => void;
}

const AcademyDetailsSheet = ({ academy, isOpen, onClose }: AcademyDetailsSheetProps) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [browserUrl, setBrowserUrl] = useState<string | null>(null);
  const [showBrowser, setShowBrowser] = useState(false);
  
  useEffect(() => {
    setActiveImageIndex(0);
    setIsCopied(false);
  }, [academy]);
  
  if (!academy) return null;
  
  const handlePrevImage = () => {
    if (!academy.images?.length) return;
    setActiveImageIndex((prev) => (prev === 0 ? academy.images!.length - 1 : prev - 1));
  };
  
  const handleNextImage = () => {
    if (!academy.images?.length) return;
    setActiveImageIndex((prev) => (prev === academy.images!.length - 1 ? 0 : prev + 1));
  };
  
  const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={`star-${i}`} className="h-4 w-4 fill-yellow-400 text-yellow-400" />);
    }
    
    if (hasHalfStar) {
      stars.push(
        <div key="half-star" className="relative">
          <Star className="h-4 w-4 text-gray-300" />
          <div className="absolute inset-0 overflow-hidden w-1/2">
            <Star className="h-4 w-4 fill-yellow-400 text-yellow-400" />
          </div>
        </div>
      );
    }
    
    const emptyStars = 5 - Math.ceil(rating);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Star key={`empty-star-${i}`} className="h-4 w-4 text-gray-300" />);
    }
    
    return <div className="flex items-center">{stars}</div>;
  };
  
  const formatDate = (timestamp: number) => {
    try {
      return format(new Date(timestamp * 1000), 'PPP');
    } catch (e) {
      return 'Unknown date';
    }
  };

  const copyPhoneNumber = () => {
    if (academy.phone) {
      navigator.clipboard.writeText(academy.phone)
        .then(() => {
          setIsCopied(true);
          toast({
            description: "Phone number copied to clipboard",
            duration: 2000,
          });
          setTimeout(() => setIsCopied(false), 2000);
        })
        .catch(() => {
          toast({
            description: "Failed to copy phone number",
            variant: "destructive",
            duration: 2000,
          });
        });
    }
  };
  
  const handleOpenGoogleMaps = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    let mapUrl: string | null = null;
    if (academy.maps_url) {
      mapUrl = academy.maps_url;
    } else if (academy.place_id) {
      mapUrl = `https://www.google.com/maps/place/?q=place_id:${academy.place_id}`;
    }
    
    if (!mapUrl) return;
    
    if (isMobile) {
      const googleMapsAppUrl = `https://maps.google.com/?q=${encodeURIComponent(academy.address || '')}&cid=${academy.place_id}`;
      window.location.href = googleMapsAppUrl;
    } else {
      window.open(mapUrl, '_blank', 'noopener,noreferrer');
    }
  };
  
  const handleExternalLink = (url: string) => {
    if (url) {
      if (url.startsWith('tel:')) {
        window.location.href = url;
      } else {
        setBrowserUrl(url);
        setShowBrowser(true);
      }
    }
  };
  
  const handleCloseBrowser = () => {
    setShowBrowser(false);
    setBrowserUrl(null);
  };

  const displayPhone = academy.phone || "+91 98765 43210";
  
  const academyImage = getExternalImage(academy);
  
  return (
    <>
      <Sheet open={isOpen} onOpenChange={(open) => !open && onClose()}>
        <SheetContent 
          side="bottom" 
          className="p-0 rounded-t-3xl max-h-[90vh] overflow-auto bg-gradient-to-br from-white to-orange-50/30 shadow-xl border-t border-orange-100"
          hideCloseButton
        >
          <div className="sticky top-0 z-10 bg-white">
            <div className="flex justify-center pt-2">
              <div className="w-12 h-1.5 bg-gray-300 rounded-full my-2" />
            </div>

            <div className="relative h-64 w-full overflow-hidden">
              <img 
                src={academyImage} 
                alt={academy.name} 
                className="w-full h-full object-cover" 
              />
              
              <div className="absolute top-2 right-2">
                <Badge className="bg-orange-500/90 hover:bg-orange-500 text-white font-medium px-3 py-1">
                  External
                </Badge>
              </div>

              <Button
                size="icon"
                variant="ghost"
                onClick={onClose}
                className="absolute top-2 left-2 bg-black/30 hover:bg-black/50 text-white rounded-full"
                aria-label="Close sheet"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            
            {academy.is_external && academy.data_source === 'google_places' && (
              <div className="flex items-center justify-center py-2 bg-white border-b border-gray-100">
                <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
              </div>
            )}
          </div>

          <div className="p-5">
            <h2 className="text-2xl font-bold text-gray-900 mb-1">{academy.name}</h2>
            <p className="text-gray-600 mb-4">
              {academy.description || `Fitness center in ${academy.city}`}
            </p>
            
            <div className="space-y-5">
              <div className="flex flex-wrap gap-2">
                {academy.sports?.map((sport) => (
                  <Badge key={sport} variant="outline" className="bg-orange-50 text-orange-700 border-orange-200">
                    {sport}
                  </Badge>
                ))}
              </div>
              
              <div className="space-y-4">
                <div className="flex items-start">
                  <MapPin className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900">Location</h4>
                    <p className="text-gray-600">{academy.address || 'Location unavailable'}, {academy.city}, {academy.state || ''} {academy.pincode || ''}</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <Clock className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900">Opening Hours</h4>
                    {academy.opening_hours && academy.opening_hours.length > 0 ? (
                      <div className="text-gray-600 text-sm">
                        {academy.opening_hours.map((hours, index) => (
                          <p key={index}>{hours}</p>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-600">{academy.opening_time || 'Not specified'} - {academy.closing_time || 'Not specified'}</p>
                    )}
                  </div>
                </div>
                
                <div className="flex items-start">
                  <Users className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900">Capacity</h4>
                    <p className="text-gray-600">Up to {academy.max_capacity || 'N/A'} people</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <Phone className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900">Contact</h4>
                    <div className="flex items-center">
                      <p className="text-gray-600">{displayPhone}</p>
                      <Button 
                        variant="ghost" 
                        size="icon" 
                        className="h-8 w-8 ml-2 rounded-full" 
                        onClick={copyPhoneNumber}
                      >
                        {isCopied ? (
                          <Check className="h-4 w-4 text-green-500" />
                        ) : (
                          <Copy className="h-4 w-4 text-gray-500" />
                        )}
                        <span className="sr-only">Copy phone number</span>
                      </Button>
                    </div>
                  </div>
                </div>
                
                {academy.website && (
                  <div className="flex items-start">
                    <Globe className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <h4 className="font-medium text-gray-900">Website</h4>
                      <button 
                        onClick={() => handleExternalLink(academy.website!)} 
                        className="text-orange-600 hover:text-orange-800 flex items-center group"
                      >
                        Visit website
                        <ExternalLink className="h-3.5 w-3.5 ml-1 transition-transform group-hover:translate-x-0.5" />
                      </button>
                    </div>
                  </div>
                )}
                
                {!academy.is_bookable && (academy.place_id || academy.maps_url) && (
                  <div className="flex items-start">
                    <Map className="h-5 w-5 text-orange-500 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <h4 className="font-medium text-gray-900">Google Maps</h4>
                      <button 
                        onClick={handleOpenGoogleMaps}
                        className="text-orange-600 hover:text-orange-800 flex items-center group"
                      >
                        Open on Google Maps
                        <ExternalLink className="h-3.5 w-3.5 ml-1 transition-transform group-hover:translate-x-0.5" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              
              {(academy.rating !== null && academy.rating > 0) && (
                <div className="border-t border-gray-100 pt-4">
                  <div className="flex items-center gap-2 mb-2">
                    {renderStars(academy.rating)}
                    <span className="font-medium text-gray-900">{academy.rating.toFixed(1)}</span>
                    {academy.total_ratings && academy.total_ratings > 0 && (
                      <span className="text-gray-500 text-sm">({academy.total_ratings} ratings)</span>
                    )}
                  </div>
                  
                  {academy.reviews && academy.reviews.length > 0 && (
                    <div className="mt-3 space-y-3">
                      <h4 className="font-medium text-gray-900">Recent Reviews</h4>
                      {academy.reviews.slice(0, 2).map((review, index) => (
                        <div key={index} className="bg-white/80 p-3 rounded-lg">
                          <div className="flex items-center justify-between mb-1">
                            <span className="font-medium">{review.author_name}</span>
                            <div className="flex items-center">
                              <span className="text-yellow-500 mr-1">{review.rating.toFixed(1)}</span>
                              <Star className="h-3.5 w-3.5 fill-yellow-400 text-yellow-400" />
                            </div>
                          </div>
                          <p className="text-gray-600 text-sm line-clamp-3">{review.text}</p>
                          <p className="text-gray-400 text-xs mt-1">{formatDate(review.time)}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              
              <div className="border-t border-gray-100 pt-5">
                <div className="flex justify-between items-center">
                  <p className="text-xl font-bold text-gray-900">
                    {academy.is_bookable ? (
                      <>₹{academy.monthly_fee}<span className="text-sm font-normal text-gray-600">/month</span></>
                    ) : (
                      <span className="text-sm font-normal text-gray-600">Available on inquiry</span>
                    )}
                  </p>
                  
                  {!academy.is_bookable && (academy.place_id || academy.maps_url) ? (
                    <Button 
                      className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white flex items-center group rounded-full shadow-lg shadow-blue-200"
                      onClick={handleOpenGoogleMaps}
                    >
                      <Map className="mr-2 h-4 w-4" />
                      Open on Google Maps
                    </Button>
                  ) : (
                    <Button 
                      className="bg-gradient-to-r from-orange-600 to-amber-600 hover:from-orange-700 hover:to-amber-700 text-white flex items-center group rounded-full shadow-lg shadow-orange-200"
                      onClick={copyPhoneNumber}
                    >
                      <Phone className="mr-2 h-4 w-4" />
                      {isCopied ? "Copied!" : displayPhone}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
      
      {browserUrl && (
        <InAppBrowser 
          url={browserUrl} 
          isOpen={showBrowser} 
          onClose={handleCloseBrowser} 
        />
      )}
    </>
  );
};

export default AcademyDetailsSheet;
