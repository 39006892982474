
import { format } from "date-fns";
import { Calendar, Clock, MapPin, CheckCircle, Ticket } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export interface VenuePass {
  id: string;
  venue_id: string;
  facility_name: string;
  pass_type: string;
  total_sessions: number;
  sessions_used: number;
  price: number;
  purchase_date: string;
  expiry_date: string;
  status: string;
  venue: {
    name: string;
    city: string;
  };
}

export interface VenuePassSession {
  id: string;
  pass_id: string;
  venue_id: string;
  facility_name: string;
  session_date: string;
  session_time: string;
  status: string;
  used_at: string | null;
  venue: {
    name: string;
    city: string;
  };
}

const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), 'dd MMM yyyy');
  } catch (error) {
    return 'Invalid date';
  }
};

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'active':
      return 'bg-gradient-to-r from-green-500 to-emerald-600 text-white';
    case 'expired':
      return 'bg-gradient-to-r from-gray-500 to-gray-600 text-white';
    case 'pending':
      return 'bg-gradient-to-r from-amber-500 to-yellow-600 text-white';
    default:
      return 'bg-gradient-to-r from-blue-500 to-blue-600 text-white';
  }
};

interface VenuePassCardProps {
  pass: VenuePass;
  sessions?: VenuePassSession[];
}

const VenuePassCard = ({ pass, sessions = [] }: VenuePassCardProps) => {
  const navigate = useNavigate();
  
  const upcomingSessions = sessions
    .filter(session => session.status.toLowerCase() === 'scheduled')
    .slice(0, 2);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Card className="premium-card overflow-hidden shadow-lg border-0 hover:shadow-2xl transition-all duration-300 group relative">
        {/* Gold shimmer effect overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-amber-200/30 via-yellow-100/30 to-amber-100/30 z-0 opacity-80" />
        <div className="absolute -inset-1 bg-gradient-to-r from-amber-400 via-yellow-300 to-amber-500 rounded-xl blur opacity-20 group-hover:opacity-30 transition duration-300" />
        
        {/* Top gold ribbon */}
        <div className="h-3 bg-gradient-to-r from-amber-500 via-yellow-400 to-amber-600 relative z-10" />
        
        <CardContent className="p-0 relative z-10">
          <div className="p-5 bg-gradient-to-br from-amber-50 to-yellow-50 dark:from-amber-900/20 dark:to-yellow-900/20 relative overflow-hidden">
            {/* Gold light animation */}
            <div className="absolute -top-24 -right-24 w-40 h-40 bg-gradient-to-br from-yellow-300/20 via-amber-200/20 to-yellow-400/20 rounded-full blur-xl animate-pulse" />
            <div className="absolute -bottom-24 -left-24 w-40 h-40 bg-gradient-to-br from-yellow-400/20 via-amber-300/20 to-amber-500/20 rounded-full blur-xl animate-pulse" />
            
            {/* Pass type and facility with gold accents */}
            <div className="flex justify-between items-start relative">
              <div>
                <h3 className="text-xl font-bold bg-gradient-to-r from-amber-700 via-yellow-600 to-amber-800 bg-clip-text text-transparent group-hover:from-amber-800 group-hover:via-yellow-700 group-hover:to-amber-900 transition-all">
                  {pass.pass_type}
                </h3>
                <div className="flex items-center text-amber-800 dark:text-amber-300 mt-1">
                  <Ticket className="h-4 w-4 mr-1.5 text-amber-600 dark:text-amber-400" />
                  {pass.facility_name}
                </div>
              </div>
              <Badge className={`${getStatusColor(pass.status)} shadow-md`}>
                {pass.status.charAt(0).toUpperCase() + pass.status.slice(1)}
              </Badge>
            </div>
          </div>
          
          {/* Luxurious content section */}
          <div className="p-5 space-y-4 bg-gradient-to-br from-white to-amber-50/50 dark:from-gray-900 dark:to-amber-950/10">
            <div className="space-y-3 text-gray-700 dark:text-amber-100">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gradient-to-br from-amber-100 to-yellow-100 dark:from-amber-900/40 dark:to-yellow-900/40 shadow-inner">
                  <MapPin className="h-4 w-4 text-amber-600 dark:text-amber-400" />
                </div>
                <span className="ml-3">{pass.venue.name}, {pass.venue.city}</span>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gradient-to-br from-amber-100 to-yellow-100 dark:from-amber-900/40 dark:to-yellow-900/40 shadow-inner">
                  <Calendar className="h-4 w-4 text-amber-600 dark:text-amber-400" />
                </div>
                <span className="ml-3">Valid until: {formatDate(pass.expiry_date)}</span>
              </div>
              
              {/* Sessions counter with premium styling */}
              <div className="mt-4 p-3 rounded-xl bg-gradient-to-br from-amber-50 to-yellow-50 dark:from-amber-900/20 dark:to-yellow-900/20 border border-amber-100 dark:border-amber-800/30 shadow-sm">
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-amber-800 dark:text-amber-300 font-medium">Sessions Used:</span>
                  <span className="font-bold text-amber-900 dark:text-amber-200">{pass.sessions_used} / {pass.total_sessions}</span>
                </div>
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2.5 text-xs flex rounded-full bg-amber-100 dark:bg-amber-900/50">
                    <div 
                      style={{ width: `${(pass.sessions_used / pass.total_sessions) * 100}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-amber-500 to-yellow-500 rounded-full transition-all duration-500 ease-in-out"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Upcoming sessions with gold accents */}
            {upcomingSessions.length > 0 && (
              <div className="space-y-2">
                <h4 className="text-sm font-semibold text-amber-800 dark:text-amber-300 flex items-center">
                  <Clock className="h-4 w-4 mr-1.5 text-amber-600 dark:text-amber-400" />
                  Upcoming Sessions
                </h4>
                <div className="space-y-2">
                  {upcomingSessions.map((session) => (
                    <div 
                      key={session.id}
                      className="flex items-center bg-gradient-to-r from-amber-50 to-yellow-50 dark:from-amber-900/20 dark:to-yellow-900/20 rounded-lg p-3 text-sm border border-amber-100 dark:border-amber-800/30 shadow-sm"
                    >
                      <div className="flex items-center justify-center w-7 h-7 rounded-full bg-gradient-to-br from-amber-300 to-yellow-400 mr-3">
                        <Calendar className="h-3.5 w-3.5 text-amber-900" />
                      </div>
                      <span className="text-amber-800 dark:text-amber-300 mr-3 font-medium">{formatDate(session.session_date)}</span>
                      <div className="flex items-center justify-center w-7 h-7 rounded-full bg-gradient-to-br from-amber-300 to-yellow-400 mr-3">
                        <Clock className="h-3.5 w-3.5 text-amber-900" />
                      </div>
                      <span className="text-amber-800 dark:text-amber-300 font-medium">{session.session_time}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Premium footer - REMOVED THE VIEW DETAILS BUTTON */}
            <div className="pt-3 mt-2 border-t border-amber-200 dark:border-amber-800/30">
              <div className="text-amber-700 dark:text-amber-400 text-sm font-medium flex items-center">
                <div className="w-5 h-5 rounded-full flex items-center justify-center bg-amber-100 dark:bg-amber-900/40 mr-2">
                  <Calendar className="h-3 w-3 text-amber-600 dark:text-amber-400" />
                </div>
                Purchased: {formatDate(pass.purchase_date)}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default VenuePassCard;
