
import { Route, Routes as RouterRoutes } from "react-router-dom";
import Index from "@/pages/Index";
import Auth from "@/pages/Auth";
import Venues from "@/pages/Venues";
import BookVenue from "@/pages/BookVenue";
import BookingConfirmation from "@/pages/BookingConfirmation";
import MyBookings from "@/pages/MyBookings";
import BeFit from "@/pages/BeFit";
import AICoaching from "@/pages/AICoaching";
import AISupport from "@/pages/AISupport";
import Gyms from "@/pages/Gyms";
import Academies from "@/pages/Academies";
import Coaches from "@/pages/Coaches";
import CoachDetail from "@/pages/CoachDetail";
import CoachRegister from "@/pages/CoachRegister";
import CoachDashboardPage from "@/pages/CoachDashboardPage";
import Communities from "@/pages/Communities";
import LiveFeed from "@/pages/LiveFeed";
import Competitions from "@/pages/Competitions";
import CreateCompetition from "@/pages/CreateCompetition";
import CompetitionDetail from "@/pages/CompetitionDetail";
import EventsAndGames from "@/pages/EventsAndGames";
import BracketGPT from "@/pages/BracketGPT";
import Products from "@/pages/Products";
import Shop from "@/pages/Shop";
import Profile from "@/pages/Profile";
import Support from "@/pages/Support";
import Terms from "@/pages/Terms";
import Privacy from "@/pages/Privacy";
import Refund from "@/pages/Refund";
import Partners from "@/pages/Partners";
import Investors from "@/pages/Investors";
import JoinPlatform from "@/pages/JoinPlatform";
import AlsoByUs from "@/pages/AlsoByUs";
import Quizzes from "@/pages/Quizzes";
import Memberships from "@/pages/Memberships";
import MembershipSuccess from "@/pages/MembershipSuccess";
import DeleteAccount from "@/pages/DeleteAccount";
import Careers from "@/pages/Careers";
import OurJourney from "@/pages/OurJourney";
import NotFound from "@/pages/NotFound";
import Settings from "@/pages/Settings";
import Games from "@/pages/Games";
import Favorites from "@/pages/Favorites";
import Admin from "@/pages/Admin";
import AdminDashboard from "@/pages/AdminDashboard";
import AdminVenueManager from "@/pages/AdminVenueManager";
import CoachImageUpdater from "@/pages/CoachImageUpdater";
import CoachAccountCreator from "@/pages/CoachAccountCreator";
import ProductDemo from "@/pages/ProductDemo";
import Offers from "@/pages/Offers";
import Search from "@/pages/Search";
import VenueImageUpdater from "@/pages/VenueImageUpdater";
import VenuePasses from "@/pages/VenuePasses";
import PassSuccess from "@/pages/PassSuccess";
// Import ScrollToTop component correctly
import ScrollToTop from "@/components/ScrollToTop";
// Import Toaster component correctly
import { Toaster } from "@/components/ui/toaster";
import Footer from "@/components/Footer";

const Routes = () => {
  return (
    <>
      <ScrollToTop />
      <Toaster />
      <RouterRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/venues" element={<Venues />} />
        <Route path="/book-venue/:id" element={<BookVenue />} />
        <Route path="/venue-passes/:id?" element={<VenuePasses />} />
        <Route path="/booking-confirmation" element={<BookingConfirmation />} />
        <Route path="/my-bookings" element={<MyBookings />} />
        <Route path="/be-fit" element={<BeFit />} />
        <Route path="/ai-coaching" element={<AICoaching />} />
        <Route path="/ai-support" element={<AISupport />} />
        <Route path="/gyms" element={<Gyms />} />
        <Route path="/academies" element={<Academies />} />
        <Route path="/coaches" element={<Coaches />} />
        <Route path="/coaches/:id" element={<CoachDetail />} />
        <Route path="/coach/dashboard" element={<CoachDashboardPage />} />
        <Route path="/coach/register" element={<CoachRegister />} />
        <Route path="/coach/image-updater" element={<CoachImageUpdater />} />
        <Route path="/coach/account-creator" element={<CoachAccountCreator />} />
        <Route path="/communities" element={<Communities />} />
        <Route path="/games" element={<Games />} />
        <Route path="/live-feed" element={<LiveFeed />} />
        <Route path="/competitions" element={<Competitions />} />
        <Route path="/competitions/new" element={<CreateCompetition />} />
        <Route path="/competitions/:id" element={<CompetitionDetail />} />
        <Route path="/events-and-games" element={<EventsAndGames />} />
        <Route path="/bracketgpt" element={<BracketGPT />} />
        <Route path="/products" element={<Products />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/join-platform" element={<JoinPlatform />} />
        <Route path="/also-by-us" element={<AlsoByUs />} />
        <Route path="/quizzes" element={<Quizzes />} />
        <Route path="/memberships" element={<Memberships />} />
        <Route path="/membership-success" element={<MembershipSuccess />} />
        <Route path="/pass-success" element={<PassSuccess />} />
        <Route path="/deleteaccount" element={<DeleteAccount />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/our-journey" element={<OurJourney />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/product-demo" element={<ProductDemo />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/search" element={<Search />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/venue/:id" element={<AdminVenueManager />} />
        <Route path="/admin/venue-images" element={<VenueImageUpdater />} />
        <Route path="*" element={<NotFound />} />
      </RouterRoutes>
      <Footer />
    </>
  );
};

export default Routes;
