import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { VenueHeader } from "@/components/booking/VenueHeader";
import { DateSelector } from "@/components/booking/DateSelector";
import { TimeSlots } from "@/components/booking/TimeSlots";
import { BookingForm } from "@/components/booking/BookingForm";
import { useAuth } from "@/components/AuthProvider";
import { initiatePayment } from "@/services/paymentService";
import { SportSelector } from "@/components/booking/SportSelector";
import { MaintenanceOverlay } from "@/components/booking/MaintenanceOverlay";
import LoadingScreen from "@/components/ui/loading-screen";
import BackButton from "@/components/ui/back-button";

interface Facility {
  id: string;
  name: string;
  description: string | null;
  price_per_hour: number;
  capacity: number | null;
  sort_order: number | null;
}

interface AvailabilitySlot {
  id: string;
  facility_id: string;
  start_time: string;
  end_time: string;
  is_booked: boolean;
}

interface VenueWithFacilities {
  id: string;
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  images: string[];
  facilities: Facility[];
  available_sports?: string[];
}

const BookVenue = () => {
  const { id: venueId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedFacility, setSelectedFacility] = useState<string>("");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>([]);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const { toast } = useToast();
  const { user } = useAuth();
  const bookingFormRef = useRef<HTMLDivElement>(null);
  const [isMaintenanceTime, setIsMaintenanceTime] = useState(false);
  const [hasCheckedFormFields, setHasCheckedFormFields] = useState(false);

  useEffect(() => {
    const checkMaintenanceTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      
      if ((hours === 0 || hours > 0) && (hours < 5 || (hours === 5 && minutes < 30))) {
        setIsMaintenanceTime(true);
      } else {
        setIsMaintenanceTime(false);
      }
    };
    
    checkMaintenanceTime();
    
    const interval = setInterval(checkMaintenanceTime, 60000);
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!hasCheckedFormFields) {
      const checkAndRefreshIfNeeded = async () => {
        if (user) {
          try {
            const { data: profile } = await supabase
              .from('profiles')
              .select('email, phone')
              .eq('id', user.id)
              .single();
              
            if (!profile?.email && !profile?.phone) {
              console.log("No contact information found. Refreshing page...");
              window.location.reload();
            }
          } catch (error) {
            console.error("Error checking profile:", error);
          }
        }
        setHasCheckedFormFields(true);
      };
      
      checkAndRefreshIfNeeded();
    }
  }, [user, hasCheckedFormFields]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sportParam = params.get('sport');
    
    if (sportParam) {
      const capitalizedSport = sportParam.charAt(0).toUpperCase() + sportParam.slice(1).toLowerCase();
      console.log("Sport from URL:", capitalizedSport);
    }
  }, [location.search]);

  console.log("Attempting to fetch venue with ID:", venueId);

  const { data: venue, isLoading: venueLoading, error: venueError } = useQuery({
    queryKey: ["venue", venueId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("venues")
        .select(`
          id,
          name,
          description,
          address,
          city,
          state,
          pincode,
          images,
          available_sports,
          facilities!facilities_venue_id_fkey (
            id,
            name,
            description,
            price_per_hour,
            capacity,
            sort_order
          )
        `)
        .eq("id", venueId)
        .maybeSingle();

      if (error) {
        console.error("Supabase error:", error);
        throw error;
      }
      
      if (!data) {
        console.error("No venue found with ID:", venueId);
        throw new Error("Venue not found");
      }

      if (data.facilities) {
        data.facilities.sort((a, b) => {
          if (a.name.includes("Court 5") || a.name.includes("Court 6")) {
            return -1;
          }
          if (b.name.includes("Court 5") || b.name.includes("Court 6")) {
            return 1;
          }
          if (a.sort_order !== null && b.sort_order !== null) {
            return a.sort_order - b.sort_order;
          }
          return a.name.localeCompare(b.name);
        });
      }

      console.log("Fetched venue data:", data);
      return data as VenueWithFacilities;
    },
  });

  useEffect(() => {
    if (venue?.available_sports?.length) {
      let sports: string[] = venue.available_sports;
      
      if (!sports.length && venue.facilities.length) {
        const sportDetectionPatterns = [
          { pattern: /badminton/i, sport: "Badminton" },
          { pattern: /tennis/i, sport: "Tennis" },
          { pattern: /pickleball/i, sport: "Pickleball" }
        ];
        
        const detectedSports = new Set<string>();
        venue.facilities.forEach(facility => {
          sportDetectionPatterns.forEach(({ pattern, sport }) => {
            if (pattern.test(facility.name)) {
              detectedSports.add(sport);
            }
          });
        });
        
        sports = Array.from(detectedSports);
      }

      const params = new URLSearchParams(location.search);
      const sportParam = params.get('sport');
      
      if (sportParam && sports.length) {
        const capitalizedSport = sportParam.charAt(0).toUpperCase() + sportParam.slice(1).toLowerCase();
        const sportExists = sports.some(sport => 
          sport.toLowerCase() === capitalizedSport.toLowerCase()
        );
        
        if (sportExists) {
          const exactSport = sports.find(sport => 
            sport.toLowerCase() === capitalizedSport.toLowerCase()
          );
          setSelectedSport(exactSport || capitalizedSport);
        } else if (sports.length) {
          setSelectedSport(sports[0]);
        }
      } else if (sports.length) {
        setSelectedSport(sports[0]);
      }
    }
  }, [venue, location.search]);

  const { data: availableSlots, isLoading: slotsLoading } = useQuery({
    queryKey: ["slots", selectedDate.toISOString().split('T')[0], venue?.facilities],
    enabled: !!venue?.facilities,
    queryFn: async () => {
      console.log("Running slots query with facilities:", venue.facilities);
      
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      console.log("Checking for slots with IST date:", selectedDate.toISOString().split('T')[0]);
      console.log("Checking for slots between:", startOfDay.toISOString(), "and", endOfDay.toISOString());
      console.log("Facility IDs being queried:", venue.facilities.map(f => f.id));

      const { data: slots, error: slotsError } = await supabase
        .from("availability_slots")
        .select("*")
        .in("facility_id", venue.facilities.map(f => f.id))
        .gte("start_time", startOfDay.toISOString())
        .lte("end_time", endOfDay.toISOString());

      if (slotsError) {
        console.error("Error fetching slots:", slotsError);
        throw slotsError;
      }

      console.log("Fetched slots:", slots?.length || 0, slots);
      return slots || [];
    },
  });

  const filteredFacilities = useMemo(() => {
    if (!venue?.facilities || !selectedSport) return [];
    
    return venue.facilities.filter(facility => {
      if (selectedSport === "Badminton") {
        return facility.name.toLowerCase().includes("badminton");
      } else if (selectedSport === "Tennis") {
        return facility.name.toLowerCase().includes("tennis");
      } else if (selectedSport === "Pickleball") {
        return facility.name.toLowerCase().includes("pickleball");
      }
      return true;
    });
  }, [venue?.facilities, selectedSport]);

  const availableSports = useMemo(() => {
    if (venue?.available_sports?.length) {
      return venue.available_sports;
    }
    
    if (!venue?.facilities) return [];
    
    const sportSet = new Set<string>();
    venue.facilities.forEach(facility => {
      const name = facility.name.toLowerCase();
      if (name.includes("badminton")) sportSet.add("Badminton");
      if (name.includes("tennis")) sportSet.add("Tennis");
      if (name.includes("pickleball")) sportSet.add("Pickleball");
    });
    
    return Array.from(sportSet);
  }, [venue]);

  const handleSlotSelect = (slotId: string) => {
    setSelectedTimeSlots(prev => {
      if (prev.includes(slotId)) {
        return prev.filter(id => id !== slotId);
      }
      return [...prev, slotId];
    });
  };

  const handleClearFacilitySelections = (facilityId: string) => {
    if (availableSlots) {
      const facilitySlotIds = availableSlots
        .filter(slot => slot.facility_id === facilityId)
        .map(slot => slot.id);
      
      setSelectedTimeSlots(prev => 
        prev.filter(slotId => !facilitySlotIds.includes(slotId))
      );
    }
  };

  const scrollToBookingForm = () => {
    if (bookingFormRef.current) {
      bookingFormRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const calculateTotalAmount = () => {
    if (!venue?.facilities || !availableSlots) return {
      subtotal: 0,
      processingFee: 0,
      gst: 0,
      total: 0
    };
    
    const facilitySlotCounts = new Map<string, number>();
    
    selectedTimeSlots.forEach(slotId => {
      const slot = availableSlots.find(s => s.id === slotId);
      if (slot) {
        const count = facilitySlotCounts.get(slot.facility_id) || 0;
        facilitySlotCounts.set(slot.facility_id, count + 1);
      }
    });

    let subtotal = 0;
    facilitySlotCounts.forEach((slotCount, facilityId) => {
      const facility = venue.facilities.find(f => f.id === facilityId);
      if (facility) {
        let pricePerHour = facility.price_per_hour;
        
        if (facility.name === "Badminton Court 1" || 
            facility.name === "Badminton Court 2" || 
            facility.name === "Badminton Court 3" || 
            facility.name === "Badminton Court 4") {
          pricePerHour = 400;
        } else if (facility.name === "Badminton Court 5" || 
                   facility.name === "Badminton Court 6") {
          pricePerHour = 208;
        } else if (facility.name === "Tennis Court") {
          pricePerHour = 400;
        } else if (facility.name === "Pickleball Court") {
          pricePerHour = 400;
        }
        
        subtotal += pricePerHour * slotCount;
      }
    });

    const processingFee = Math.round(subtotal * 0.02);
    const gst = Math.round(subtotal * 0.18);
    const total = subtotal + processingFee + gst;

    return {
      subtotal,
      processingFee,
      gst,
      total
    };
  };

  const getSelectedFacilityName = () => {
    if (!availableSlots || !venue?.facilities || selectedTimeSlots.length === 0) {
      return "";
    }
    
    const selectedSlot = availableSlots.find(slot => selectedTimeSlots.includes(slot.id));
    if (!selectedSlot) return "";
    
    const facility = venue.facilities.find(f => f.id === selectedSlot.facility_id);
    return facility?.name || "";
  };

  const getSelectedTimeSlotObjects = () => {
    if (!availableSlots) return [];
    
    return availableSlots
      .filter(slot => selectedTimeSlots.includes(slot.id))
      .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
  };

  const selectedFacilitiesMap = useMemo(() => {
    if (!availableSlots || !venue?.facilities) return {};
    
    const facilitiesMap: Record<string, { 
      facilityName: string; 
      slots: Array<{id: string; start_time: string;}>;
    }> = {};
    
    selectedTimeSlots.forEach(slotId => {
      const slot = availableSlots.find(s => s.id === slotId);
      if (slot) {
        const facility = venue.facilities.find(f => f.id === slot.facility_id);
        if (facility) {
          if (!facilitiesMap[facility.id]) {
            facilitiesMap[facility.id] = {
              facilityName: facility.name,
              slots: []
            };
          }
          facilitiesMap[facility.id].slots.push({
            id: slot.id,
            start_time: slot.start_time
          });
        }
      }
    });
    
    Object.values(facilitiesMap).forEach(facility => {
      facility.slots.sort((a, b) => 
        new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
    });
    
    return facilitiesMap;
  }, [selectedTimeSlots, availableSlots, venue?.facilities]);

  const handleSubmit = async () => {
    if (!user) {
      toast({
        variant: "destructive",
        title: "Authentication Required",
        description: "Please sign in to make a booking",
      });
      return;
    }

    const selectedSlot = availableSlots?.find(slot => selectedTimeSlots.includes(slot.id));
    if (!selectedSlot) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please select a time slot",
      });
      return;
    }

    const selectedFacilityDetails = venue?.facilities.find(
      f => f.id === selectedSlot.facility_id
    );

    if (!selectedFacilityDetails) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Facility not found",
      });
      return;
    }

    const startTime = new Date(selectedSlot.start_time);
    const endTime = new Date(selectedSlot.end_time);
    const totalAmount = calculateTotalAmount().total;

    const bookingId = crypto.randomUUID();

    try {
      if (user) {
        const bookingDate = selectedDate.toISOString().split('T')[0];
        console.log("Creating booking with IST date:", bookingDate);
        
        const { error } = await supabase
          .from('bookings')
          .insert([
            {
              id: bookingId,
              venue_id: venueId,
              user_id: user.id,
              booking_date: bookingDate,
              start_time: startTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
              end_time: endTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
              total_amount: totalAmount,
              status: 'pending',
              payment_status: 'pending',
              facility_name: selectedFacilityDetails.name
            }
          ]);

        if (error) {
          console.error('Error creating booking:', error);
          toast({
            variant: "destructive",
            title: "Booking Failed",
            description: "There was an error creating your booking. Please try again.",
          });
          throw error;
        }
      }

      const bookingDate = selectedDate.toISOString().split('T')[0];
      console.log("Initiating payment with IST date:", bookingDate);
      
      await initiatePayment({
        bookingId,
        amount: totalAmount,
        customerName: contactName,
        customerEmail: contactEmail,
        customerPhone: contactPhone,
        venueName: venue.name,
        facilityName: selectedFacilityDetails.name,
        bookingDate: bookingDate,
        startTime: startTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        endTime: endTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        venueId: venue.id
      });
    } catch (error) {
      console.error('Error in booking process:', error);
      toast({
        variant: "destructive",
        title: "Booking Failed",
        description: "There was an error processing your booking. Please try again.",
      });
    }
  };

  if (isMaintenanceTime) {
    return <MaintenanceOverlay />;
  }

  if (venueError) {
    return (
      <div className="min-h-screen pt-32 flex flex-col items-center justify-center p-4">
        <div className="text-xl text-red-600 mb-4">Error loading venue</div>
        <div className="text-gray-600 mb-4">
          {venueError instanceof Error ? venueError.message : "Unknown error occurred"}
        </div>
        <button
          onClick={() => navigate("/venues")}
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
        >
          Return to Venues
        </button>
      </div>
    );
  }

  if (venueLoading || slotsLoading) {
    return (
      <div className="min-h-screen pt-32 flex items-center justify-center">
        <LoadingScreen size="md" />
      </div>
    );
  }

  if (!venue) {
    return (
      <div className="min-h-screen pt-32 flex flex-col items-center justify-center p-4">
        <div className="text-xl text-red-600 mb-4">Venue not found</div>
        <div className="text-gray-600 mb-4">
          The venue you're looking for doesn't exist or has been removed.
        </div>
        <button
          onClick={() => navigate("/venues")}
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
        >
          Return to Venues
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50 dark:bg-gray-900">
      <BackButton className="fixed top-24 left-4 z-50" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <VenueHeader
              name={venue.name}
              description={venue.description || ""}
              address={venue.address || ""}
              city={venue.city || ""}
              state={venue.state || ""}
              pincode={venue.pincode || ""}
              images={venue.images || []}
              id={venue.id}
            />
            
            <DateSelector 
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
            />
            
            {availableSports.length > 0 && (
              <SportSelector 
                availableSports={availableSports}
                selectedSport={selectedSport}
                onSelectSport={setSelectedSport}
              />
            )}

            <div className="space-y-6">
              {filteredFacilities.map((facility) => (
                <TimeSlots
                  key={facility.id}
                  facilityName={facility.name}
                  slots={availableSlots?.filter(slot => slot.facility_id === facility.id) || []}
                  selectedSlots={selectedTimeSlots}
                  onSlotSelect={handleSlotSelect}
                  pricePerHour={facility.price_per_hour}
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-sm"
                  titleClassName="text-gray-900 dark:text-gray-100 font-semibold"
                  onClearSelections={handleClearFacilitySelections}
                  onScrollToForm={scrollToBookingForm}
                />
              ))}
              
              {filteredFacilities.length === 0 && selectedSport && (
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm text-center">
                  <p className="text-gray-500 dark:text-gray-400">
                    No {selectedSport} facilities available at this venue.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="lg:col-span-1">
            <div className="sticky top-32">
              <BookingForm
                bookingId={venueId}
                contactName={contactName}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
                specialRequests={specialRequests}
                totalAmount={calculateTotalAmount()}
                onContactNameChange={setContactName}
                onContactEmailChange={setContactEmail}
                onContactPhoneChange={setContactPhone}
                onSpecialRequestsChange={setSpecialRequests}
                onSubmit={handleSubmit}
                isValid={selectedTimeSlots.length > 0 && !!contactName && !!contactEmail && !!contactPhone}
                venueName={venue.name}
                facilityName={getSelectedFacilityName()}
                bookingDate={selectedDate}
                selectedTimeSlots={getSelectedTimeSlotObjects()}
                selectedFacilitiesMap={selectedFacilitiesMap}
                formRef={bookingFormRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookVenue;
