
import { Facility } from "./VenuePassesScreen";
import { useIsMobile } from "@/hooks/use-mobile";
import { MapPin, Clock, DollarSign, ChevronRight } from "lucide-react";
import { CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const FacilitySelector = ({ 
  facilities, 
  onSelectFacility 
}: { 
  facilities: Facility[], 
  onSelectFacility: (facility: Facility) => void 
}) => {
  const isMobile = useIsMobile();
  
  // Sort facilities alphabetically by name
  const sortedFacilities = [...facilities].sort((a, b) => 
    a.name.localeCompare(b.name)
  );

  return (
    <CardContent className="p-6">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
          Select a Facility
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mt-1">
          Choose the facility you want to purchase a pass for
        </p>
      </div>

      {sortedFacilities.length === 0 ? (
        <div className="p-4 rounded-xl bg-gray-50 dark:bg-gray-800 text-center">
          <p className="text-gray-600 dark:text-gray-400">No facilities found for this venue.</p>
        </div>
      ) : (
        <div className="space-y-4">
          {sortedFacilities.map((facility) => (
            <div key={facility.id} className="relative group">
              <div 
                className="p-4 rounded-xl bg-gradient-to-r from-indigo-50 to-purple-50 dark:from-indigo-900/20 dark:to-purple-900/20 border border-indigo-100 dark:border-indigo-800/30 cursor-pointer hover:shadow-md transition-all duration-300"
                onClick={() => onSelectFacility(facility)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <h3 className="font-bold text-lg text-gray-900 dark:text-white">{facility.name}</h3>
                    {facility.description && (
                      <p className="text-gray-600 dark:text-gray-400 text-sm mt-1 line-clamp-2">{facility.description}</p>
                    )}
                    <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-4 mt-3">
                      <div className="flex items-center text-gray-600 dark:text-gray-400">
                        <MapPin className="h-4 w-4 mr-1 text-indigo-500" />
                        <span className="text-xs">Indoor</span>
                      </div>
                      <div className="flex items-center text-gray-600 dark:text-gray-400">
                        <Clock className="h-4 w-4 mr-1 text-indigo-500" />
                        <span className="text-xs">60 min session</span>
                      </div>
                      <div className="flex items-center text-gray-600 dark:text-gray-400">
                        <DollarSign className="h-4 w-4 mr-1 text-indigo-500" />
                        <span className="text-xs">₹{facility.price_per_hour}/hour</span>
                      </div>
                    </div>
                  </div>
                  <ChevronRight className="h-5 w-5 text-indigo-500 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={`mt-6 ${isMobile ? 'text-sm' : ''}`}>
        <div className="p-4 rounded-lg bg-indigo-50 dark:bg-indigo-900/20 border border-indigo-100 dark:border-indigo-800/30">
          <h4 className="font-semibold text-indigo-700 dark:text-indigo-300 flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path>
            </svg>
            About Facility Passes
          </h4>
          <p className="mt-2 text-sm text-indigo-600 dark:text-indigo-400">
            Facility passes give you access to the selected facility for a specific number of sessions per week. All passes expire after one month from the date of purchase.
          </p>
        </div>
      </div>
    </CardContent>
  );
};

export default FacilitySelector;
