import { useState, useEffect } from "react";
import { Venue } from "@/types/venue";
import FacilitySelector from "./FacilitySelector";
import PassTypeSelector from "./PassTypeSelector";
import TimeSlotSelector from "./TimeSlotSelector";
import PassSummary from "./PassSummary";
import { Card } from "@/components/ui/card";
import { useIsMobile } from "@/hooks/use-mobile";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export type Facility = {
  id: string;
  name: string;
  description?: string | null;
  price_per_hour: number;
  venue_id: string;
};

export type PassType = {
  id: string;
  name: string;
  description: string;
  sessionsPerWeek: number;
  totalSessions: number;
  discount: number;
};

export type SelectedTimeSlot = {
  day: string;
  time: string;
};

const VenuePassesScreen = ({ venue }: { venue: Venue }) => {
  const isMobile = useIsMobile();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<Facility | null>(null);
  const [selectedPassType, setSelectedPassType] = useState<PassType | null>(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<SelectedTimeSlot[]>([]);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFacilities = async () => {
      setLoading(true);
      try {
        if (!venue.id || typeof venue.id !== 'string') {
          console.error("Invalid venue ID:", venue.id, "Full venue object:", venue);
          toast.error("Unable to load facilities: Invalid venue data");
          generateFallbackFacilities();
          return;
        }
        
        console.info(`Attempting to fetch facilities for venue ID: ${venue.id}`);
        
        const { data, error } = await supabase
          .from('facilities')
          .select('id, name, description, price_per_hour, venue_id')
          .eq('venue_id', venue.id);
        
        if (error) {
          console.error("Error fetching facilities:", error);
          toast.error("Failed to load facilities. Please try again.");
          generateFallbackFacilities();
        } else if (data && data.length > 0) {
          console.info(`Fetched ${data.length} facilities for venue ${venue.name}: `, data);
          const facilitiesWithVenueId = data.map(facility => ({
            ...facility,
            venue_id: facility.venue_id || venue.id
          })) as Facility[];
          setFacilities(facilitiesWithVenueId);
          setLoading(false);
        } else {
          console.info(`No facilities found for venue ${venue.name} (ID: ${venue.id}), generating fallbacks`);
          generateFallbackFacilities();
        }
      } catch (error) {
        console.error("Failed to fetch facilities:", error);
        generateFallbackFacilities();
      }
    };

    const generateFallbackFacilities = () => {
      console.info("Generating fallback facilities for venue:", venue.name);
      const availableSports = venue.available_sports || [];
      const generatedFacilities: Facility[] = availableSports.map((sport, index) => ({
        id: `facility-${index}`,
        name: `${sport} ${index === 0 ? 'Court' : index === 1 ? 'Field' : 'Arena'} ${index + 1}`,
        description: `Professional ${sport} ${index === 0 ? 'court' : index === 1 ? 'field' : 'arena'} with top-quality equipment and amenities.`,
        price_per_hour: venue.price_per_hour || 500 + (index * 100),
        venue_id: venue.id
      }));

      if (generatedFacilities.length < 3) {
        const additionalFacilities = [
          {
            id: "facility-default-1",
            name: "Badminton Court",
            description: "Professional badminton court with top-quality equipment and amenities.",
            price_per_hour: venue.price_per_hour || 500,
            venue_id: venue.id
          },
          {
            id: "facility-default-2",
            name: "Basketball Court",
            description: "Professional basketball court with top-quality equipment and amenities.",
            price_per_hour: venue.price_per_hour || 600,
            venue_id: venue.id
          },
          {
            id: "facility-default-3",
            name: "Football Field",
            description: "Professional football field with top-quality equipment and amenities.",
            price_per_hour: venue.price_per_hour || 700,
            venue_id: venue.id
          },
        ].slice(0, 3 - generatedFacilities.length);
        
        setFacilities([...generatedFacilities, ...additionalFacilities]);
      } else {
        setFacilities(generatedFacilities);
      }
      setLoading(false);
    };

    fetchFacilities();
  }, [venue]);

  const passTypes: PassType[] = [
    {
      id: "weekly",
      name: "Weekly Pass",
      description: "4 sessions (once a week)",
      sessionsPerWeek: 1,
      totalSessions: 4,
      discount: 10,
    },
    {
      id: "biweekly",
      name: "Biweekly Pass",
      description: "8 sessions (twice a week)",
      sessionsPerWeek: 2,
      totalSessions: 8,
      discount: 15,
    },
    {
      id: "triweekly",
      name: "Triweekly Pass",
      description: "12 sessions (thrice a week)",
      sessionsPerWeek: 3,
      totalSessions: 12,
      discount: 20,
    },
  ];

  const handleFacilitySelect = (facility: Facility) => {
    setSelectedFacility(facility);
    setStep(2);
  };

  const handlePassTypeSelect = (passType: PassType) => {
    setSelectedPassType(passType);
    setSelectedTimeSlots([]);
    setStep(3);
  };

  const handleTimeSlotSelect = (timeSlots: SelectedTimeSlot[]) => {
    setSelectedTimeSlots(timeSlots);
    setStep(4);
  };

  const handleGoBack = (targetStep: number) => {
    setStep(targetStep);
  };

  return (
    <div className={`space-y-6 ${isMobile ? 'pb-20 mt-8' : 'pb-10'}`}>
      <div className="flex justify-between mb-6 px-2">
        {[1, 2, 3, 4].map((s) => (
          <div key={s} className="flex flex-col items-center">
            <div 
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                step >= s 
                  ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white' 
                  : 'bg-gray-200 text-gray-500'
              }`}
            >
              {s}
            </div>
            <span className={`text-xs mt-1 ${isMobile ? 'hidden' : 'block'} ${
              step >= s ? 'text-gray-800 dark:text-gray-200' : 'text-gray-400'
            }`}>
              {s === 1 ? 'Facility' : s === 2 ? 'Pass Type' : s === 3 ? 'Time Slots' : 'Summary'}
            </span>
          </div>
        ))}
        <div className={`absolute left-0 right-0 ${isMobile ? 'top-[200px]' : 'top-[160px]'} z-0`}>
          <div className="relative h-0.5 mx-auto max-w-3xl">
            <div className="absolute left-[12%] right-[12%] h-0.5 bg-gray-200">
              <div 
                className="h-0.5 bg-gradient-to-r from-indigo-600 to-purple-600 transition-all duration-300" 
                style={{ width: `${(step - 1) * 33.33}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <Card className="overflow-hidden border-0 shadow-lg rounded-2xl bg-white dark:bg-gray-800">
        {loading ? (
          <div className="flex justify-center items-center p-12">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
            <span className="ml-3 text-gray-600 dark:text-gray-400">Loading facilities...</span>
          </div>
        ) : (
          <>
            {step === 1 && (
              <FacilitySelector 
                facilities={facilities} 
                onSelectFacility={handleFacilitySelect} 
              />
            )}
            
            {step === 2 && selectedFacility && (
              <PassTypeSelector 
                passTypes={passTypes} 
                facility={selectedFacility}
                onSelectPassType={handlePassTypeSelect}
                onGoBack={() => handleGoBack(1)}
              />
            )}
            
            {step === 3 && selectedFacility && selectedPassType && (
              <TimeSlotSelector 
                facility={selectedFacility}
                passType={selectedPassType}
                selectedTimeSlots={selectedTimeSlots}
                onTimeSlotSelect={handleTimeSlotSelect}
                onGoBack={() => handleGoBack(2)}
              />
            )}
            
            {step === 4 && selectedFacility && selectedPassType && selectedTimeSlots.length > 0 && (
              <PassSummary 
                facility={selectedFacility}
                passType={selectedPassType}
                selectedTimeSlots={selectedTimeSlots}
                venueName={venue.name}
                onGoBack={() => handleGoBack(3)}
              />
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default VenuePassesScreen;
