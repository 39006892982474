import { Facility, PassType, SelectedTimeSlot } from "./VenuePassesScreen";
import { CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Calendar, Clock, CreditCard, CheckCircle, Ticket, ArrowRight, Mail } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { createRazorpayPassOrder, savePassToDatabase, verifyRazorpayPassPayment } from "@/services/passPaymentService";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const PassSummary = ({
  facility,
  passType,
  selectedTimeSlots,
  venueName,
  onGoBack
}: {
  facility: Facility;
  passType: PassType;
  selectedTimeSlots: SelectedTimeSlot[];
  venueName: string;
  onGoBack: () => void;
}) => {
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);
  const [hasCompletedVerification, setHasCompletedVerification] = useState(false);

  const addLog = (message: string) => {
    setLogs(prevLogs => [...prevLogs, `${new Date().toLocaleTimeString()}: ${message}`]);
    console.log(`PAYMENT LOG: ${message}`);
  };

  const calculateSubtotal = () => {
    const originalPrice = facility.price_per_hour * passType.totalSessions;
    const discountAmount = (originalPrice * passType.discount) / 100;
    return originalPrice - discountAmount;
  };

  const subtotal = calculateSubtotal();
  const gstAmount = subtotal * 0.18;
  const processingFee = subtotal * 0.02;
  const total = subtotal + gstAmount + processingFee;

  const formatDay = (day: string) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  const proceedToSuccessPage = () => {
    addLog("Redirecting to success page...");
    setTimeout(() => {
      navigate("/pass-success", { 
        state: { 
          passType: passType.name,
          facilityName: facility.name,
          sessions: passType.totalSessions,
          amount: total
        } 
      });
    }, 800);
  };

  const sendConfirmationEmail = async (passDetails: any, paymentId: string) => {
    try {
      addLog(`Sending confirmation email to: ${passDetails.email}`);
      
      const { data, error } = await supabase.functions.invoke('send-pass-confirmation', {
        body: {
          passDetails,
          paymentId
        }
      });
      
      if (error) {
        addLog(`Error sending confirmation email: ${error.message}`);
        console.error("Email sending error:", error);
        return false;
      }
      
      addLog("Confirmation email sent successfully!");
      return true;
    } catch (error) {
      addLog(`Exception while sending confirmation email: ${error instanceof Error ? error.message : String(error)}`);
      console.error("Email sending exception:", error);
      return false;
    }
  };

  const handleProceedToPay = async () => {
    const isValid = await form.trigger("email");
    if (!isValid) {
      toast.error("Please enter a valid email address");
      return;
    }
    
    const email = form.getValues("email");
    
    setIsProcessing(true);
    setLogs([]);
    addLog("Payment process started");
    
    try {
      if (!user) {
        addLog("User not logged in, redirecting to login page");
        toast.error("Please login to purchase a pass");
        navigate("/auth", { state: { returnTo: window.location.pathname } });
        return;
      }

      if (!facility.venue_id) {
        addLog("ERROR: Facility is missing venue_id");
        toast.error("Invalid facility data. Missing venue ID.");
        setIsProcessing(false);
        return;
      }

      addLog(`Facility object: ${JSON.stringify({
        id: facility.id,
        name: facility.name,
        venue_id: facility.venue_id,
        price: facility.price_per_hour
      })}`);

      const passDetails = {
        userId: user.id,
        venueId: facility.venue_id,
        facilityId: facility.id, 
        facilityName: facility.name,
        venueName: venueName,
        passType: passType,
        price: total,
        selectedTimeSlots,
        email: email
      };

      addLog(`Creating Razorpay order for facility: ${facility.name}, venue ID: ${facility.venue_id}, venue name: ${passDetails.venueName}, price: ₹${total.toFixed(2)}`);

      const orderData = await createRazorpayPassOrder(total, {
        venueId: facility.venue_id,
        facilityId: facility.id,
        facilityName: facility.name,
        passType: passType.name,
        email: email
      });

      if (!orderData || !orderData.order_id) {
        addLog("Failed to create payment order - no order ID received");
        throw new Error("Failed to create payment order");
      }

      addLog(`Razorpay order created successfully - Order ID: ${orderData.order_id}`);

      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      document.body.appendChild(script);

      script.onload = () => {
        addLog("Razorpay SDK loaded, opening payment modal");
        const options = {
          key: orderData.key_id,
          amount: orderData.amount,
          currency: orderData.currency,
          name: "beSportly",
          description: `${passType.name} - ${facility.name}`,
          order_id: orderData.order_id,
          prefill: {
            email: user.email || '',
            contact: ''
          },
          theme: {
            color: "#6366F1"
          },
          handler: async function(response: any) {
            try {
              addLog(`Payment successful, received payment ID: ${response.razorpay_payment_id}`);
              setHasCompletedVerification(false);

              const verificationTimer = setTimeout(() => {
                if (!hasCompletedVerification) {
                  addLog("Verification taking too long, proceeding to success page");
                  toast.success("Payment successful! Pass purchased.");
                  proceedToSuccessPage();
                }
              }, 10000);
              
              addLog("Starting to save pass to database");
              
              try {
                const updatedPassDetails = {
                  ...passDetails,
                  paymentId: response.razorpay_payment_id
                };
                
                addLog(`Preparing to call savePassToDatabase with these parameters: 
                  userId: ${updatedPassDetails.userId}
                  venueId: ${updatedPassDetails.venueId}
                  facilityId: ${updatedPassDetails.facilityId || 'none'}
                  facilityName: ${updatedPassDetails.facilityName}
                  passType: ${updatedPassDetails.passType.name}
                  total slots: ${updatedPassDetails.selectedTimeSlots.length}
                  payment ID: ${updatedPassDetails.paymentId}
                `);
                
                addLog(`Calling savePassToDatabase function with venueId: ${updatedPassDetails.venueId}`);
                
                try {
                  const savedPass = await savePassToDatabase(updatedPassDetails);
                  
                  if (savedPass) {
                    addLog(`Pass saved to database successfully with ID: ${savedPass.id || 'unknown'}`);
                    
                    if (savedPass.mobileFlow) {
                      addLog("Using mobile flow - pass creation will be handled server-side");
                    }
                    
                    addLog("Sending pass confirmation email directly");
                    await sendConfirmationEmail(updatedPassDetails, response.razorpay_payment_id);
                  } else {
                    addLog("Warning: savePassToDatabase returned undefined or null");
                  }
                } catch (saveError) {
                  addLog(`Error saving pass to database: ${saveError instanceof Error ? saveError.message : String(saveError)}`);
                  console.error("Database save error:", saveError);
                  // Continue despite the error - the verification step might succeed
                }

                addLog("Pass saved locally, starting Razorpay backend verification...");
                try {
                  const existingPassCheck = await supabase
                    .from('venue_passes')
                    .select('id')
                    .eq('payment_id', response.razorpay_payment_id)
                    .maybeSingle();
                    
                  if (existingPassCheck.data && existingPassCheck.data.id) {
                    addLog(`Pass with payment ID ${response.razorpay_payment_id} already exists, skipping verification`);
                    clearTimeout(verificationTimer);
                    setHasCompletedVerification(true);
                    toast.success("Payment successful! Pass purchased.");
                    proceedToSuccessPage();
                  } else {
                    try {
                      const verificationResult = await verifyRazorpayPassPayment(
                        response.razorpay_payment_id,
                        response.razorpay_order_id,
                        response.razorpay_signature,
                        updatedPassDetails
                      );
                      
                      clearTimeout(verificationTimer);
                      setHasCompletedVerification(true);
                      addLog(`Payment verification result: ${verificationResult ? 'success' : 'failed'}`);
                      
                      toast.success("Payment successful! Pass purchased.");
                      proceedToSuccessPage();
                    } catch (verifyError) {
                      addLog(`Error verifying payment: ${verifyError instanceof Error ? verifyError.message : String(verifyError)}`);
                      console.error("Verification error:", verifyError);
                      
                      if (isMobile) {
                        addLog("Mobile device detected, checking if pass was created despite verification error");
                        try {
                          const finalPassCheck = await supabase
                            .from('venue_passes')
                            .select('id')
                            .eq('payment_id', response.razorpay_payment_id)
                            .maybeSingle();
                            
                          if (finalPassCheck.data && finalPassCheck.data.id) {
                            addLog(`Found pass with ID ${finalPassCheck.data.id} despite verification error`);
                            clearTimeout(verificationTimer);
                            setHasCompletedVerification(true);
                            toast.success("Payment successful! Pass purchased.");
                            proceedToSuccessPage();
                            return;
                          } else {
                            addLog("No pass found after verification error");
                          }
                        } catch (finalCheckError) {
                          addLog("Error during final pass check: " + String(finalCheckError));
                        }
                      }
                      
                      clearTimeout(verificationTimer);
                      setHasCompletedVerification(true);
                      toast.success("Payment recorded! Your pass should be visible soon.");
                      proceedToSuccessPage();
                    }
                  }
                } catch (verifyError) {
                  addLog(`Error during verification process: ${verifyError instanceof Error ? verifyError.message : String(verifyError)}`);
                  console.error("Verification process error:", verifyError);
                  
                  clearTimeout(verificationTimer);
                  setHasCompletedVerification(true);
                  toast.success("Payment recorded! Your pass should be visible soon.");
                  proceedToSuccessPage();
                }
              } catch (dbError) {
                clearTimeout(verificationTimer);
                setHasCompletedVerification(true);
                const errorMessage = dbError instanceof Error ? dbError.message : String(dbError);
                addLog(`Error saving pass to database: ${errorMessage}`);
                console.error("Database error:", dbError);
                
                toast.info("Payment successful! Your pass will be processed shortly.");
                proceedToSuccessPage();
              }
            } catch (error) {
              setHasCompletedVerification(true);
              const errorMessage = error instanceof Error ? error.message : String(error);
              addLog(`Error during redirect/post-payment: ${errorMessage}`);
              console.error("Error during redirect:", error);
              
              toast.info("Payment successful! Your pass will be processed shortly.");
              proceedToSuccessPage();
            }
          },
          modal: {
            ondismiss: function() {
              addLog("Payment modal dismissed by user");
              setIsProcessing(false);
              toast.info("Payment cancelled");
            },
            escape: false,
            backdropclose: false
          }
        };

        try {
          const razorpayObject = new window.Razorpay(options);
          razorpayObject.on('payment.failed', function(response: any) {
            addLog(`Payment failed: ${response.error.description}`);
            setIsProcessing(false);
            toast.error(`Payment failed: ${response.error.description}`);
          });
          
          addLog("Opening Razorpay payment modal...");
          razorpayObject.open();
        } catch (razorpayError) {
          addLog(`Error initializing Razorpay: ${razorpayError instanceof Error ? razorpayError.message : String(razorpayError)}`);
          setIsProcessing(false);
          toast.error("Failed to initialize payment gateway. Please try again.");
        }
      };

      script.onerror = () => {
        addLog("Failed to load Razorpay script");
        console.error("Failed to load Razorpay script");
        setIsProcessing(false);
        toast.error("Failed to load payment gateway. Please try again.");
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      addLog(`Error during payment process: ${errorMessage}`);
      console.error("Payment initiation failed:", error);
      toast.error("Payment initiation failed. Please try again.");
      setIsProcessing(false);
    }
  };

  const getExpiryDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const formSchema = z.object({
    email: z.string().email("Please enter a valid email address"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user?.email || "",
    },
  });

  return (
    <CardContent className={`p-6 ${isMobile ? 'pb-16' : ''}`}>
      <div className="flex items-center mb-6">
        <button
          onClick={onGoBack}
          className="p-1 mr-2 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        <div>
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
            Pass Summary
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mt-1">
            Review and confirm your pass details
          </p>
        </div>
      </div>

      <div className="mb-6 p-5 rounded-xl bg-gradient-to-r from-indigo-50 to-purple-50 dark:from-indigo-900/10 dark:to-purple-900/10 border border-indigo-100 dark:border-indigo-800/20">
        <div className="flex items-center mb-3">
          <Ticket className="h-5 w-5 mr-2 text-indigo-600 dark:text-indigo-400" />
          <h3 className="font-bold text-lg text-gray-900 dark:text-white">
            {passType.name} - {facility.name}
          </h3>
        </div>
        
        <div className="space-y-4">
          <div>
            <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Selected Time Slots:</h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              {selectedTimeSlots.map((slot, index) => (
                <div 
                  key={index}
                  className="flex items-center p-2 bg-white dark:bg-gray-800 rounded-lg border border-indigo-100 dark:border-indigo-800/30"
                >
                  <Calendar className="h-4 w-4 mr-2 text-indigo-500 dark:text-indigo-400" />
                  <span className="text-sm text-gray-800 dark:text-gray-200">{formatDay(slot.day)}</span>
                  <Clock className="h-4 w-4 mx-2 text-indigo-500 dark:text-indigo-400" />
                  <span className="text-sm text-gray-800 dark:text-gray-200">{slot.time}</span>
                </div>
              ))}
            </div>
          </div>
          
          <div className="flex flex-wrap gap-2">
            {[
              `${passType.totalSessions} total sessions`,
              `60 minutes per session`,
              `Valid until ${getExpiryDate()}`
            ].map((detail, idx) => (
              <div key={idx} className="flex items-center bg-white dark:bg-gray-800 rounded-lg py-1 px-3 border border-indigo-100 dark:border-indigo-800/30">
                <CheckCircle className="h-3.5 w-3.5 mr-1.5 text-green-600 dark:text-green-400" />
                <span className="text-xs text-gray-800 dark:text-gray-200">{detail}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="font-semibold text-gray-800 dark:text-white mb-3">
          Price Breakdown
        </h3>
        
        <div className="space-y-2 mb-4">
          <div className="flex justify-between">
            <span className="text-gray-600 dark:text-gray-400">Subtotal ({passType.totalSessions} sessions)</span>
            <span className="text-gray-800 dark:text-gray-200">₹{subtotal.toLocaleString()}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600 dark:text-gray-400">GST (18%)</span>
            <span className="text-gray-800 dark:text-gray-200">₹{gstAmount.toLocaleString()}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600 dark:text-gray-400">Payment Processing Fee (2%)</span>
            <span className="text-gray-800 dark:text-gray-200">₹{processingFee.toLocaleString()}</span>
          </div>
          <div className="pt-2 mt-2 border-t border-gray-200 dark:border-gray-700">
            <div className="flex justify-between font-bold">
              <span className="text-gray-800 dark:text-gray-200">Total</span>
              <span className="text-indigo-700 dark:text-indigo-400">₹{total.toLocaleString()}</span>
            </div>
            <div className="flex justify-end">
              <span className="text-green-600 dark:text-green-400 text-xs">
                You save ₹{((facility.price_per_hour * passType.totalSessions) - subtotal).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center bg-gray-50 dark:bg-gray-800/50 p-4 rounded-lg mb-6">
        <CreditCard className="h-5 w-5 mr-2 text-gray-700 dark:text-gray-300" />
        <span className="text-gray-700 dark:text-gray-300 font-medium">Secure Payment</span>
      </div>

      <div className="mb-6">
        <Form {...form}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center bg-gradient-to-r from-purple-50 to-pink-50 dark:from-purple-900/20 dark:to-pink-900/20 p-4 rounded-lg border border-purple-100 dark:border-purple-800/50 focus:border-purple-400 dark:focus:border-purple-600 transition-all duration-300">
                  <Mail className="h-5 w-5 mr-2 text-purple-600 dark:text-purple-400" />
                  <span className="text-purple-700 dark:text-purple-300 font-medium">Email for Pass Confirmation</span>
                </div>
                <FormControl>
                  <Input 
                    placeholder="Enter your email address" 
                    className="bg-white dark:bg-gray-800 border-2 border-purple-200 dark:border-purple-800/50 focus:border-purple-400 dark:focus:border-purple-600 transition-all duration-300"
                    required
                    {...field} 
                  />
                </FormControl>
                <FormMessage className="text-red-500 text-sm mt-1" />
              </FormItem>
            )}
          />
        </Form>
      </div>

      {isProcessing && logs.length > 0 && (
        <div className="mb-6 bg-gray-50 dark:bg-gray-800/50 p-4 rounded-lg max-h-64 overflow-y-auto text-xs font-mono border border-indigo-100 dark:border-indigo-800/30">
          <h4 className="font-semibold mb-2 text-gray-800 dark:text-gray-200">Payment Process Logs:</h4>
          <div className="space-y-1">
            {logs.map((log, index) => (
              <div 
                key={index} 
                className={`text-${
                  log.includes("ERROR") || log.includes("error") || log.includes("failed") || log.includes("TIMEOUT") 
                    ? "red" 
                    : log.includes("success") || log.includes("Successfully")
                      ? "green" 
                      : "gray"
                }-700 dark:text-${
                  log.includes("ERROR") || log.includes("error") || log.includes("failed") || log.includes("TIMEOUT")
                    ? "red" 
                    : log.includes("success") || log.includes("Successfully")
                      ? "green" 
                      : "gray"
                }-300`}
              >
                {log}
              </div>
            ))}
          </div>
        </div>
      )}

      <Button
        onClick={handleProceedToPay}
        disabled={isProcessing}
        className="w-full py-6 bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white font-semibold rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        {isProcessing ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </span>
        ) : (
          <span className="flex items-center">
            Proceed to Payment <ArrowRight className="ml-2 h-5 w-5" />
          </span>
        )}
      </Button>

      <div className="mt-4 text-center">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          By proceeding, you agree to our <a href="#" className="text-indigo-600 dark:text-indigo-400 hover:underline">Terms of Service</a> and <a href="#" className="text-indigo-600 dark:text-indigo-400 hover:underline">Refund Policy</a>
        </p>
      </div>
    </CardContent>
  );
};

export default PassSummary;
