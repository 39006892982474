
import { PassType, Facility } from "./VenuePassesScreen";
import { ChevronLeft, Ticket, CheckCircle } from "lucide-react";
import { CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";

const PassTypeSelector = ({ 
  passTypes, 
  facility,
  onSelectPassType,
  onGoBack
}: { 
  passTypes: PassType[], 
  facility: Facility,
  onSelectPassType: (passType: PassType) => void,
  onGoBack: () => void
}) => {
  const isMobile = useIsMobile();

  const calculatePrice = (passType: PassType) => {
    const originalPrice = facility.price_per_hour * passType.totalSessions;
    const discountAmount = (originalPrice * passType.discount) / 100;
    return originalPrice - discountAmount;
  };

  return (
    <CardContent className="p-6">
      <div className="flex items-center mb-6">
        <button 
          onClick={onGoBack}
          className="p-1 mr-2 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        <div>
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
            Select Pass Type
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mt-1">
            For {facility.name}
          </p>
        </div>
      </div>

      <div className="space-y-4">
        {passTypes.map((passType) => {
          const price = calculatePrice(passType);
          const pricePerSession = Math.round(price / passType.totalSessions);
          
          return (
            <div key={passType.id} className="relative group">
              <div 
                className="p-5 rounded-xl bg-white dark:bg-gray-900 border border-indigo-100 dark:border-indigo-800/30 cursor-pointer hover:shadow-md transition-all duration-300 overflow-hidden"
                onClick={() => onSelectPassType(passType)}
              >
                <div className="absolute -right-4 -top-4 w-24 h-24 bg-gradient-to-br from-indigo-500 to-purple-600 transform rotate-45 opacity-10"></div>
                
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <Ticket className="h-5 w-5 mr-2 text-indigo-600 dark:text-indigo-400" />
                      <h3 className="font-bold text-lg text-gray-900 dark:text-white">{passType.name}</h3>
                    </div>
                    <p className="text-gray-600 dark:text-gray-400 text-sm mt-1">{passType.description}</p>
                    
                    <div className="mt-3">
                      <div className="flex items-baseline">
                        <span className="text-2xl font-bold text-indigo-700 dark:text-indigo-400">₹{price.toLocaleString()}</span>
                        <span className="ml-2 text-sm line-through text-gray-500">
                          ₹{(facility.price_per_hour * passType.totalSessions).toLocaleString()}
                        </span>
                        <span className="ml-2 text-xs bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-400 px-2 py-0.5 rounded">
                          Save {passType.discount}%
                        </span>
                      </div>
                      <p className="text-gray-600 dark:text-gray-400 text-xs mt-1">
                        Just ₹{pricePerSession}/session
                      </p>
                    </div>
                    
                    <div className="mt-3 flex flex-wrap gap-y-1 gap-x-2">
                      {[
                        `${passType.totalSessions} sessions`,
                        `${passType.sessionsPerWeek} session${passType.sessionsPerWeek > 1 ? 's' : ''}/week`,
                        "Valid for 1 month"
                      ].map((feature, idx) => (
                        <div key={idx} className="flex items-center text-xs text-gray-600 dark:text-gray-400">
                          <CheckCircle className="h-3.5 w-3.5 mr-1 text-green-600 dark:text-green-400" />
                          {feature}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={`mt-6 ${isMobile ? 'text-sm' : ''}`}>
        <div className="p-4 rounded-lg bg-yellow-50 dark:bg-yellow-900/10 border border-yellow-100 dark:border-yellow-800/20">
          <h4 className="font-semibold text-yellow-700 dark:text-yellow-500 flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
            </svg>
            Special Offer
          </h4>
          <p className="mt-2 text-sm text-yellow-600 dark:text-yellow-400">
            Purchase a Triweekly Pass and get exclusive access to premium facilities during peak hours!
          </p>
        </div>
      </div>
    </CardContent>
  );
};

export default PassTypeSelector;
